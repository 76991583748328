import { combineReducers } from 'redux'

import { articles } from './article.reducers'
import { events } from './events.reducers'
import { form } from './form.reducers'
import { magazine } from './magazine.reducers'
import { menu } from './menu.reducers'
import { page } from './page.reducers'
import { places } from './place.reducers'
import { alerts } from './alert.reducers'

const rootReducer = combineReducers({
    articles,
    events,
    form,
    magazine,
    menu,
    page,
    places,
    alerts,
})

export default rootReducer
