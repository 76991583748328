export const eventsConstants = {
    GET_EVENTS_SUCCESS: 'GET_EVENTS_SUCCESS',
    GET_EVENT_SUCCESS: 'GET_EVENT_SUCCESS',
    GET_MORE_EVENTS_SUCCESS: 'GET_MORE_EVENTS_SUCCESS',
    GET_FILTERED_EVENTS_SUCCESS: 'GET_FILTERED_EVENTS_SUCCESS',
    GET_EVENTS_CAROUSEL_SUCCESS: 'GET_EVENTS_CAROUSEL_SUCCESS',


    GET_EVENTS_ERROR: 'GET_EVENTS_ERROR',
    GET_EVENT_ERROR: 'GET_EVENT_ERROR',
    GET_MORE_EVENTS_ERROR: 'GET_MORE_EVENTS_ERROR',
    GET_FILTERED_EVENTS_ERROR: 'GET_FILTERED_EVENTS_ERROR',
    GET_EVENTS_CAROUSEL_ERROR: 'GET_EVENTS_CAROUSEL_ERROR',


    CLEAR_FILTERED_EVENTS: 'CLEAR_FILTERED_EVENTS',
}
