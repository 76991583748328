import React, { Component } from 'react'
import ReactGA from 'react-ga'
import { VideoModal } from '../'

class NotFound extends Component {
    constructor(props){
       super(props);

       this.state = {
          showModal: false,
       }
    }

    componentWillReceiveProps(nextProps) {
        const currentPage = this.props.location.pathname;
        const nextPage = nextProps.location.pathname;

        if (currentPage !== nextPage && process.env.NODE_ENV !== 'development') {
            ReactGA.pageview(window.location.pathname)
        }
    }

    openModal = () => this.setState({ [`showModal`]: true })
    closeModal = () => this.setState({ [`showModal`]: false })

    render() {
        return (
            <div>
            <div className="content" id="not-found">
                <div className="container">
                    <div className="text">
                        <div className="top">Sorry, we<br/> glitched out for<br/> a second there.</div>
                        <div className="bottom">
                            <div>404</div>
                            <div onClick={ () => this.openModal() }>Page could not be found,<br/> but watch this </div>
                        </div>
                    </div>
                    <svg viewBox="0 0 1650 1485" width="1650" height="1485" xmlns="http://www.w3.org/2000/svg" className="grid">
                        <rect width="100%" height="100%" className="bg"/>
                        <g className="row-1">
                            <path d="m151 150c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m301 150c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m451 150c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m601 150c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m751 150c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m901 150c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m1051 150c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m1201 150c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m1351 150c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m1501 150c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m1651 150c0-82.8-67.6-150-151-150v150z"/>
                        </g>
                        <g className="row-2">
                            <path d="m151 149c-82.8 0-151 67.6-151 151h151z"/>
                            <path d="m149 300c82.8 0 151-68.6 151-152h-151z"/>
                            <path d="m451 149c-82.8 0-151 67.6-151 151h151z"/>
                            <path d="m449 300c82.8 0 151-68.6 151-152h-151z"/>
                            <path d="m751 149c-82.8 0-151 67.6-151 151h151z"/>
                            <path d="m749 300c82.8 0 151-68.6 151-152h-151z"/>
                            <path d="m1051 149c-82.8 0-151 67.6-151 151h151z"/>
                            <path d="m1049 300c82.8 0 151-68.6 151-152h-151z"/>
                            <path d="m1351 149c-82.8 0-151 67.6-151 151h151z"/>
                            <path d="m1349 300c82.8 0 151-68.6 151-152h-151z"/>
                            <path d="m1651 149c-82.8 0-151 67.6-151 151h151z"/>
                        </g>
                        <g className="row-3">
                            <path d="m151 450c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m301 450c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m451 450c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m601 450c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m751 450c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m901 450c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m1051 450c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m1201 450c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m1351 450c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m1501 450c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m1651 450c0-82.8-67.6-150-151-150v150z"/>
                        </g>
                        <g className="row-4">
                            <path d="m151 450c-83.4 0-151 67.2-151 150h151z"/>
                            <path d="m301 450c-83.4 0-151 67.2-151 150h151z"/>
                            <path d="m451 450c-83.4 0-151 67.2-151 150h151z"/>
                            <path d="m601 450c-83.4 0-151 67.2-151 150h151z"/>
                            <path d="m751 450c-83.4 0-151 67.2-151 150h151z"/>
                            <path d="m901 450c-83.4 0-151 67.2-151 150h151z"/>
                            <path d="m1051 450c-83.4 0-151 67.2-151 150h151z"/>
                            <path d="m1201 450c-83.4 0-151 67.2-151 150h151z"/>
                            <path d="m1351 450c-83.4 0-151 67.2-151 150h151z"/>
                            <path d="m1651 450c-83.4 0-151 67.2-151 150h151z"/>
                            <path d="m1501 450c-83.4 0-151 67.2-151 150h151z"/>
                        </g>
                        <g className="row-5">
                            <path d="m151 600c-83.4 0-151 67.2-151 150h151z"/>
                            <path d="m300 750c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m451 600c-83.4 0-151 67.2-151 150h151z"/>
                            <path d="m600 750c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m751 600c-83.4 0-151 67.2-151 150h151z"/>
                            <path d="m900 750c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m1051 600c-83.4 0-151 67.2-151 150h151z"/>
                            <path d="m1200 750c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m1351 600c-83.4 0-151 67.2-151 150h151z"/>
                            <path d="m1500 750c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m1650 600c-82.8 0-150 67.2-150 150h150z"/>
                        </g>
                        <g className="row-6">
                            <path d="m151 900c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m301 900c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m451 900c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m601 900c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m751 900c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m901 900c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m1051 900c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m1201 900c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m1351 900c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m1501 900c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m1651 900c0-82.8-67.6-150-151-150v150z"/>
                        </g>
                        <g className="row-7">
                            <path d="m151 899c-82.8 0-151 67.6-151 151h151z"/>
                            <path d="m149 1050c82.8 0 151-68.6 151-152h-151z"/>
                            <path d="m451 899c-82.8 0-151 67.6-151 151h151z"/>
                            <path d="m449 1050c82.8 0 151-68.6 151-152h-151z"/>
                            <path d="m751 899c-82.8 0-151 67.6-151 151h151z"/>
                            <path d="m749 1050c82.8 0 151-68.6 151-152h-151z"/>
                            <path d="m1051 899c-82.8 0-151 67.6-151 151h151z"/>
                            <path d="m1049 1050c82.8 0 151-68.6 151-152h-151z"/>
                            <path d="m1351 899c-82.8 0-151 67.6-151 151h151z"/>
                            <path d="m1349 1050c82.8 0 151-68.6 151-152h-151z"/>
                            <path d="m1651 899c-82.8 0-151 67.6-151 151h151z"/>
                        </g>
                        <g className="row-8">
                            <path d="m151 1200c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m301 1200c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m451 1200c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m601 1200c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m751 1200c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m901 1200c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m1051 1200c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m1201 1200c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m1351 1200c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m1501 1200c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m1651 1200c0-82.8-67.6-150-151-150v150z"/>
                        </g>
                        <g className="row-9">
                            <path d="m151 1200c-83.4 0-151 67.2-151 150h151z"/>
                            <path d="m301 1200c-83.4 0-151 67.2-151 150h151z"/>
                            <path d="m451 1200c-83.4 0-151 67.2-151 150h151z"/>
                            <path d="m601 1200c-83.4 0-151 67.2-151 150h151z"/>
                            <path d="m751 1200c-83.4 0-151 67.2-151 150h151z"/>
                            <path d="m901 1200c-83.4 0-151 67.2-151 150h151z"/>
                            <path d="m1051 1200c-83.4 0-151 67.2-151 150h151z"/>
                            <path d="m1201 1200c-83.4 0-151 67.2-151 150h151z"/>
                            <path d="m1351 1200c-83.4 0-151 67.2-151 150h151z"/>
                            <path d="m1501 1200c-83.4 0-151 67.2-151 150h151z"/>
                            <path d="m1651 1200c-83.4 0-151 67.2-151 150h151z"/>
                        </g>
                        <g className="row-10">
                            <path d="m151 1499c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m301 1499c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m451 1499c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m601 1499c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m751 1499c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m901 1499c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m1051 1499c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m1201 1499c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m1351 1499c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m1501 1499c0-82.8-67.6-150-151-150v150z"/>
                            <path d="m1651 1499c0-82.8-67.6-150-151-150v150z"/>
                        </g>
                    </svg>
                </div>
            </div>

            <VideoModal key={ 0 }
                        url='https://vimeo.com/314308463'
                        showModal={ this.state[`showModal`] }
                        closeModal={ this.closeModal }
                        videoNumber={ 0 } />
            </div>
        )
    }

    componentDidMount() {
        document.body.classList.add('page-404');
        setTimeout(() => {
            document.body.classList.add('page-404-state-1');
            setTimeout(() => {
                document.body.classList.add('page-404-state-2');
                setTimeout(() => {
                    document.body.classList.add('page-404-state-3');
                }, 200);
            }, 200);
        }, 400);
    }
}

export { NotFound }
