import { magazineConstants } from '../_constants'
import { magazineServices } from '../_services'

export const magazineActions = {
    getMagazines,
}

function getMagazines() {
    return dispatch => {
        magazineServices.getMagazines()
            .then(
                magazines => dispatch(success(magazines)),
                error => dispatch(failure(error))
            )
    }
    function success(magazines) { return { type: magazineConstants.GET_MAGAZINES_SUCCESS, magazines } }
    function failure(error) { return { type: magazineConstants.GET_MAGAZINES_ERROR, error } }
}
