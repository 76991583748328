import React, { Component } from 'react'
import '../../css/App.scss'
import Carousel from 'nuka-carousel'
import { VideoModal } from '../'

class ImageCarousel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            numberOfImages: 1,
            images: this.props.images,
            page: this.props.page,
            autoPlay: this.props.autoPlay,
        }

        if (this.state.images) {
            this.state.images.forEach((e, i) => {
                if (e.video_url) {
                    this.setState({ [`showModal${i}`]: false });
                }
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        // this updates the state when it is changed in the parent element
        this.setState({ images: nextProps.images })
        if (nextProps.images) {
            nextProps.images.forEach((e, i) => {
                if (e.video_url) {
                    this.setState({ [`showModal${i}`]: false });
                }
            })
        }
    }

    openModal = (i) => this.setState({ [`showModal${i}`]: true })
    closeModal = (i) => this.setState({ [`showModal${i}`]: false })

    render() {
        const imageCarouselSettings = {
            // https://github.com/FormidableLabs/nuka-carousel
            wrapAround: true,
            slidesToShow: this.state.numberOfImages,
            slidesToScroll: this.state.numberOfImages,
            // heightMode: 'first',
            swiping: false,
            dragging: false,
            speed: 1500,
            easing: 'easePolyInOut',
            enableKeyboardControls: false,
            autoplay: this.state.autoPlay,
        }

        if (this.state.autoPlay) imageCarouselSettings.autoplayInterval = 8000

        let leftButtonClass = 'carousel-control'
        let rightButtonClass = 'carousel-control'
        if (this.state.images && this.state.images.length !== 1) {
            if (this.state.page !== 'home') {
                leftButtonClass += ' left-arrow'
                rightButtonClass += ' right-arrow'
            } else {
                leftButtonClass += ' wedge wedge-prev'
                rightButtonClass += ' wedge wedge-next'
            }
        } else {
            leftButtonClass += 'hide'
            rightButtonClass += 'hide'
        }


        return (
            <div>
                <Carousel { ...imageCarouselSettings } className="image-carousel"
                    renderCenterLeftControls={({ previousSlide }) => (
                        <div className={ leftButtonClass  } onClick={ previousSlide } />
                    )}
                    renderCenterRightControls={({ nextSlide }) => (
                        <div className={ rightButtonClass } onClick={ nextSlide } />
                    )}
                >
                    {
                        this.state.images ? this.state.images.map((e, i) => {
                            return (
                                <div key={ i } className="item-container">
                                    {
                                        e.video_url ? (
                                            <div className="video-item">
                                                <div className="video-thumbnail">
                                                    <div className="video-play-button" onClick={ () => this.openModal(i) } />
                                                </div>
                                            </div>

                                        ) : (
                                            <div className="image-item">
                                                {
                                                    this.state.page === 'things-to-do'  ? (
                                                        <div style={{ backgroundImage: `url(${e.thumbnail})` }}>
                                                            <div className="tinted">
                                                                <p className="legend place" dangerouslySetInnerHTML={{ __html: e.name }} />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div style={{ backgroundImage: `url(${e.thumbnail})` }}>
                                                            <div className="tinted">
                                                                <p className="legend" dangerouslySetInnerHTML={{ __html: e.caption }} />
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            )
                        // something has to be returned, or the carousel will break,
                        // return an empty string when there are no images
                        }) : () => ''
                    }
                </Carousel>
                {
                    this.state.images ? this.state.images.map((e, i) => {
                        if (e.video_url) {
                            return (
                                <VideoModal key={ i }
                                            url={ e.video_url }
                                            showModal={ this.state[`showModal${i}`] }
                                            closeModal={ this.closeModal }
                                            videoNumber={ i } />
                            )
                        }
                    }) : ''
                }
            </div>
        )
    }
}

export { ImageCarousel }
