import React, { Component } from 'react'

class PlaceList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            places: this.props.places,
            map: this.props.map,
            scroll: this.props.scroll,
            show: this.props.show
        }
    }

    componentWillReceiveProps(nextProps) {
        // this updates the state when it is changed in the parent element
        this.setState({
            places: nextProps.places,
            map: nextProps.map,
            show: nextProps.show,
        })
    }

    categories = (cats) => {
        if (cats) {
            return cats.join(', ')
        }
    }

    appendMapIcons = (level, mapHeight) => {
        const icons = this.state.places.filter((e) => e.level.match(level));
        let url
        if (level === 'Level 1') url = 'skewed_map_icon_level1'
        if (level === 'Level 2') url = 'skewed_map_icon_level2'
        if (level === 'Level 3') url = 'skewed_map_icon_level3'

        return createIcons(icons[0])


        function createIcons(place, i=0) {
            i++
            if (!icons[i]) {
                return (
                    <div className="map-icon"
                        style={{
                            backgroundImage: `url(${place[url]})`,
                            paddingBottom: `${mapHeight}%`
                        }}
                    />
                )
            }
            return(
                <div className="map-icon"
                    style={{
                        backgroundImage: `url(${place[url]})`,
                        paddingBottom: `${mapHeight}%`
                    }}
                >
                    { createIcons(icons[i], i) }
                </div>
            )
        }
    }

    render() {
        const locationClasses = {
            'Level 1': 'level-1',
            'Level 2': 'level-2',
            'Level 3': 'level-3',
            'Level 1 and Level 2': 'level-1-2',
            'Level 2 and Level 3': 'level-2-3',
            'All Levels': 'all-levels',
        }

        let level1, level2, level3

        this.state.places.forEach(e => {
            if (e.level.match('Level 1')) level1 = true
            if (e.level.match('Level 2')) level2 = true
            if (e.level.match('Level 3')) level3 = true
        })
        const mapHeight = (616 / 2110) * 100 // Skewed map height / width * 100 for percentage
        return (
            <div id="place-location-list" className={ this.state.show ? '' : 'hide' }>

                <div className="location-maps">
                    {
                        level3 ? (
                            <div className="level3-map-container">
                                <div className="places-level-3-map map"
                                    style={{
                                        backgroundImage: `url(${this.state.map.level3_skewed})`,
                                        paddingBottom: `${mapHeight}%`
                                    }}
                                >
                                    { this.appendMapIcons('Level 3', mapHeight) }
                                </div>
                            </div>
                        ) : ''
                    }
                    {
                        level2 ? (
                            <div className="places-level-2-map map"
                                 style={{
                                    backgroundImage: `url(${this.state.map.level2_skewed})`,
                                    paddingBottom: `${mapHeight}%`
                                 }}
                            >
                                { this.appendMapIcons('Level 2', mapHeight) }
                            </div>
                        ) : ''
                    }
                    {
                        level1 ? (
                            <div className="places-level-1-map map"
                                 style={{
                                    backgroundImage: `url(${this.state.map.level1_skewed})`,
                                    paddingBottom: `${mapHeight}%`
                                 }}
                            >
                                { this.appendMapIcons('Level 1', mapHeight) }
                            </div>
                        ) : ''
                    }
                </div>
                <div className="location-list">
                    <div className="locations">
                    {
                        this.state.places.sort((a, b) => a.display_number > b.display_number ? 1 : -1).map((e, i) => (
                            <div key={ i } className="location" onClick={ () => this.state.scroll(e.display_number) }>
                                <div className={ locationClasses[e.level] }>{ e.display_number }</div>
                                <div className="location-list-info">
                                    <h3 className="location-name">{ e.name }</h3>
                                    <p className="location-category">{ this.categories(e.subcategories) }</p>
                                </div>
                            </div>
                        ))
                    }
                    </div>
                </div>
            </div>
        )
    }
}

export { PlaceList }
