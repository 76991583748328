import React, { Component } from 'react'
import '../../css/App.scss'
import { time } from '../../_helpers'
import { EventModal, EventListItem } from '../'
import Modal from 'react-modal'

class EventList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            eventList: this.props.eventList,
            date: this.props.date,
            first: this.props.first,
            modalIsOpen: false,
            mobileList: this.props.mobileList,
        }
    }

    componentWillReceiveProps(nextProps) {
        // this updates the state when it is changed in the parent element
        this.setState({
            eventList: nextProps.eventList,
            date: nextProps.date,
            mobileList: nextProps.mobileList,
        })
    }

    render() {
        const dateParts = this.state.date.split('-')
        const date = `${time.getMonth(dateParts[0])} ${Number(dateParts[1])}, ${dateParts[2]}`

        return (
            <div className={ this.state.first ? 'first daily-items' : 'daily-items' }>
                { this.state.mobileList ? <div className="date info">{ date }</div> : '' }
                { this.state.eventList.map((e, i) => <EventListItem event={ e } key={ i } mobileList={ this.state.mobileList } date={ this.state.date }/>) }
            </div>
        )
    }
}

export { EventList }
