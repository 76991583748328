import React, { Component } from 'react'

class Block extends Component {
    constructor(props) {
        super(props)
        this.state = {
            content: this.props.content,
            show: false,
            first: this.props.first,
        }
    }

    render() {
        return (
            <div className={ this.state.first ? 'content-block first-block' : 'content-block' }>
                <h1 className="info about-title">{ this.state.content.title }</h1>
                <p className={ this.state.content.title === 'Current Tenants' ? 'main-content center-images' :'main-content' } dangerouslySetInnerHTML={{ __html: this.state.content.content }}></p>
                <p className={ this.state.show ? 'expanded-content' : 'expanded-content hide' } dangerouslySetInnerHTML={{ __html: this.state.content.expanded_content }}></p>
                {
                    this.state.content.expanded_content ? (
                        <div className="expanded-content-button" onClick={ () => this.setState({ show: !this.state.show }) }>
                            <div className={ this.state.show ? 'expand hide more' : 'expand more' }>More<div className="right-arrow"/></div>
                            <div className={ this.state.show ? 'expand less' : 'expand hide less' }>Less<div className="up-arrow"/></div>
                        </div>
                    ) : ''
                }
            </div>
        )
    }
}

export { Block }
