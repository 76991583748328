import { pageConstants } from '../_constants'
import { pageServices } from '../_services'

export const pageActions = {
    getPage,
    getMap,
}

function getPage(pageSlug) {
    return dispatch => {
        pageServices.getPage(pageSlug)
            .then(
                page => {
                    let key = page.slug
                    if (key === 'district-magazine') {
                        key = 'districtMagazine'
                    } else if (key === 'things-to-do') {
                        key = 'thingsToDo'
                    }

                    dispatch(success(page, key))
                },
                error => dispatch(failure(error))
            )
    }
    function success(page, key) { return { type: pageConstants.GET_PAGE_SUCCESS, page, key } }
    function failure(error) { return { type: pageConstants.GET_PAGE_FAILURE, error } }
}

function getMap() {
    return dispatch => {
        pageServices.getMap()
            .then(
                map => dispatch(success(map)),
                error => dispatch(failure(error))
            )
    }
    function success(map) { return { type: pageConstants.GET_MAP_SUCCESS, map } }
    function failure(error) { return { type: pageConstants.GET_MAP_FAILURE, error } }
}
