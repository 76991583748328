import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { menuActions } from '../../_actions'

class Header extends Component {
    toggleMenu = () => {
        const { activeMenu, dispatch } = this.props
        dispatch(menuActions.toggleMenu(activeMenu))
    }

    closeMenu = () => {
        const { dispatch } = this.props
        dispatch(menuActions.toggleMenu(true))
    }

    render() {
        const { activeMenu } = this.props
        return (
            <header id="header" className={ activeMenu ? 'magenta' : 'green' }>
                <div id="nav-button" className={ activeMenu ? 'open ' : '' } onClick={ this.toggleMenu }>
                    <span key="1"></span>
                    <span key="2"></span>
                    <span key="3"></span>
                    <span key="4"></span>
                    <span key="5"></span>
                    <span key="6"></span>
                </div>
                <Link to="/" onClick={ this.closeMenu }><div id="header-logo"></div></Link>
                <div id="s-m-icons">
                    <a href="https://twitter.com/GreenStreetHou/" target="_blank" rel="noopener noreferrer">
                        <div className="twitter"></div>
                    </a>
                    <a href="https://www.facebook.com/greenstreethou/" target="_blank" rel="noopener noreferrer">
                        <div className="facebook"></div>
                    </a>
                    <a href="https://www.instagram.com/greenstreethou/" target="_blank" rel="noopener noreferrer">
                        <div className="instagram"></div>
                    </a>
                </div>
            </header>
        )
    }
}

function mapStateToProps(state) {
    const { activeMenu } = state.menu
    return {
        activeMenu
    }
}
const connectedHeader = connect(mapStateToProps)(Header)
export { connectedHeader as Header }
