import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../css/App.scss'
import { time } from '../../_helpers'

class EventModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            events: this.props.events,
            date: this.props.date,
            close: this.props.close,
        }
    }

    componentWillReceiveProps(nextProps) {
        // this updates the state when it is changed in the parent element
        this.setState({
            events: nextProps.events,
            date: nextProps.date,
        })
    }

    fullDate = (date) => {
        const day = Number(date[1])
        const month = time.getMonth(date[0])
        const year = date[2]
        const dow = time.getDOW(date)
        return `${dow}, ${month} ${day}, ${year}`
    }

    render() {
        return (
            <div className="event-modal-list">
                <div className="close" onClick={ this.state.close }></div>
                <h1 className="date title">
                    { this.fullDate(this.state.date) }
                </h1>
                <div className="events-list">
                    {
                        this.state.events.map((e, i) => {
                            const URL = `${encodeURIComponent('https://greenstreet.mwm.partners/calendar')}/?event=${e.id}`
                            const facebookURL = `https://www.facebook.com/sharer/sharer.php?u=${URL}`
                            const twitterURL = `https://twitter.com/share?url=${URL}`

                            return (
                                <div key={ i } className="modal-event">
                                    <div className={ i === 0 ? 'left first': 'left' }>
                                        <h1 className="name title">{ e.name }</h1>
                                        {
                                            e.repeat_text ? (
                                                <p className="time">
                                                    { e.repeat_text }: { time.formatTime(e.start_time, e.end_time, e.time_info) }
                                                </p>
                                            ) : (
                                                <p className="time">
                                                    { time.formatTime(e.start_time, e.end_time, e.time_info) }
                                                </p>
                                            )
                                        }
                                        <p className="description" dangerouslySetInnerHTML={{ __html: e.description }}></p>
                                    </div>
                                    <div className={ i === 0 ? 'right first': 'right' }>
                                        {e.admission ? (
                                            <div className="admission">
                                                <h5 className="info">Admission / Registration</h5>
                                                <p>
                                                    {
                                                        e.admission.includes('http') && !e.admission.includes('href=') ? (
                                                            <a href={ e.admission } target="_blank" rel="noopener noreferrer">Purchase Tickets</a>
                                                        ) : (
                                                            <span>{ e.admission }</span>
                                                        )
                                                    }
                                                </p>
                                            </div>
                                        ) : ''}
                                        {e.locaiton ? (
                                            <div className="location">
                                                <h5 className="info">Map</h5>
                                                <Link className="location-link" to={ `/visit/?location=${encodeURIComponent(e.location)}` }>
                                                    <p>{ e.location }</p><div className="right-arrow green-arrow event-arrow"></div>
                                                </Link>
                                            </div>
                                        ) : ''}
                                        <div className="share">
                                            <h5 className="info">Share</h5>
                                            <div className="icons">
                                                <div id="s-m-icons">
                                                    <a href={ `mailto:?&subject=${e.name}&body=${URL}` }>
                                                        <div className="email green-logo" />
                                                    </a>
                                                    <a href={ twitterURL } target="_blank" rel="noopener noreferrer">
                                                        <div className="twitter green-logo"></div>
                                                    </a>
                                                    <a href={ facebookURL } target="_blank" rel="noopener noreferrer">
                                                        <div className="facebook green-logo"></div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}

export { EventModal }
