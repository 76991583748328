import { cookies } from '../_helpers'

export const articlesServices = {
    getArticles,
    getArticle,
    getMoreArticles,
}

function getArticles() {
    const requestOptions = {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
            'X-CSRFToken': cookies.getCookie('csrftoken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }

    return fetch('/api/articles/', requestOptions)
        .then(res => res.json())
        .then(data => data)
}

function getArticle(slug) {
    const requestOptions = {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
            'X-CSRFToken': cookies.getCookie('csrftoken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }

    return fetch(`/api/articles/?article=${slug}`, requestOptions)
        .then(res => res.json())
        .then(data => data[0])
}

function getMoreArticles(page) {
    const requestOptions = {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
            'X-CSRFToken': cookies.getCookie('csrftoken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }

    return fetch(`/api/articles/?page=${page}`, requestOptions)
        .then(res => res.json())
        .then(data => data)
}
