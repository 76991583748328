import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { pageActions, articleActions } from '../../_actions'
import { ArticleCard } from '../'
import { ArticleModal } from '../'
import { time } from '../../_helpers'
import Modal from 'react-modal'
import ReactGA from 'react-ga'


class News extends Component {
    constructor(props) {
        super(props)
        this.state = {
            results: 10,
            modalIsOpen: false,
        }
    }

    componentWillReceiveProps(nextProps) {
        const currentPage = this.props.location.pathname;
        const nextPage = nextProps.location.pathname;

        if (currentPage !== nextPage && process.env.NODE_ENV !== 'development') {
            ReactGA.pageview(window.location.pathname)
        }
    }

    componentDidMount() {
        const { news, calendar, dispatch, articles, article, error } = this.props

        window.scrollTo(0, 0)

        if (!news) {
            dispatch(pageActions.getPage('news'))
        }

        if (!calendar) {
            dispatch(pageActions.getPage('calendar'))
        }

        if (!articles.length) {
            dispatch(articleActions.getArticles())
        }

        const search = {}
        window.location.search.split(/\?|&/g).forEach(e => {
            const parts = e.split('=')
            search[parts[0]] = parts[1]
        })

        if (search.article && !Object.keys(article).length && !error) {
            dispatch(articleActions.getArticle(search.article))
            this.setState({ modalIsOpen: true })
        }
    }

    openModal = (e) => {
        if (e.target.className === 'close') return
        this.setState({modalIsOpen: true})
    }

    closeModal = () => this.setState({modalIsOpen: false})

    loadMore = () => {
        const { dispatch, articles, pageNumber } = this.props
        const results = this.state.results + 10
        this.setState({ results })
        if (results > articles.length) {
            dispatch(articleActions.getMoreArticles(articles, pageNumber))
        }
    }

    render() {
        const { news, calendar, articles, article } = this.props
        const page = news || {}
        const calendarPage = calendar || {}

        let dateText = ''
        if (Object.keys(article).length) {
            const date = article.dateline.split('-')
            dateText = `${time.getMonth(date[1])} ${date[2]}, ${date[0]}`
        }

        return (
            <div className="content" id="news">
                <div className="head">
                    <div className="title">
                        <Link to="/calendar" className="alt-link">{ calendarPage.title || 'Calendar' }</Link> / { page.title || 'News' }
                    </div>
                </div>
                <div className="article-list">
                    {
                        articles.slice(0, this.state.results).map((e, i) => <ArticleCard key={ i } article={ e } first={ i === 0 } />)
                    }
                </div>
                {
                    articles.length ? (
                        <div className="calendar-buttons">
                            <button className={ this.state.results >= articles.length  ? 'load-more button disabled' : 'load-more button' } onClick={ this.loadMore }>Load More</button>
                        </div>
                    ) : ''
                }

                <Modal isOpen={ this.state.modalIsOpen } shouldCloseOnOverlayClick={ true } onRequestClose={ this.closeModal }>
                    <ArticleModal article={ article } modalIsOpen={ this.state.modalIsOpen } date={ dateText } close={ this.closeModal }/>
                </Modal>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { news, calendar } = state.page
    const { articles, pageNumber, article, error } = state.articles
    return {
        news,
        calendar,
        articles,
        pageNumber,
        article,
        error,
    }
}
const connectedNews = connect(mapStateToProps)(News)
export { connectedNews as News }
