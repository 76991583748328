import React, { Component } from 'react'
import '../../css/App.scss'
import Carousel from 'nuka-carousel'
import { EventCarouselCard } from '../'

class EventCarousel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            numberOfEvents: 4,
            events: this.props.events,
            modalIsOpen: false,
            currentIndex: 0,
        }
    }

    componentWillReceiveProps(nextProps) {
        // this updates the state when it is changed in the parent element
        this.setState({
            events: nextProps.events,
        })
    }

    componentDidMount() {
        this.updateDimensions()
        window.addEventListener('resize', this.updateDimensions)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions)
    }

    updateDimensions = () => {
        let numberOfEvents
        if (window.innerWidth < 751) {
            if (this.state.numberOfEvents !== 1) {
                numberOfEvents = 1
                this.setState({ numberOfEvents })
            }
        } else if (window.innerWidth < 1050) {
            if (this.state.numberOfEvents !== 2) {
                numberOfEvents = 2
                this.setState({ numberOfEvents })
            }
        } else if (window.innerWidth < 1315){
            if (this.state.numberOfEvents !== 3) {
                numberOfEvents = 3
                this.setState({ numberOfEvents })
            }
        } else if (window.innerWidth < 1800) {
            if (this.state.numberOfEvents !== 4) {
                numberOfEvents = 4
                this.setState({ numberOfEvents })
            }
        } else if (window.innerWidth < 2600) {
            if (this.state.numberOfEvents !== 5) {
                numberOfEvents = 5
                this.setState({ numberOfEvents })
            }
        } else {
            if (this.state.numberOfEvents !== 6) {
                numberOfEvents = 6
                this.setState({ numberOfEvents })
            }
        }
    }

    render() {
        const eventCarouselSettings = {
            // https://github.com/FormidableLabs/nuka-carousel
            // wrapAround: Object.entries(this.state.events).length >= this.state.numberOfEvents + 2,
            wrapAround: false,
            slidesToShow: this.state.numberOfEvents,
            slidesToScroll: this.state.numberOfEvents,
            heightMode: 'first',
            swiping: false,
            dragging: false,
            speed: 1500,
            easing: 'easePolyInOut',
            slideWidth: 1.01,
            enableKeyboardControls: false,
            afterSlide: currentIndex => this.setState({ currentIndex })
        }

        return (
            <div className="event-carousel-wrap">
                <div className="info carousel-title">Upcoming Events</div>
                <Carousel { ...eventCarouselSettings }
                    renderCenterLeftControls={({ previousSlide }) => (
                        <div className={ `carousel-control left-arrow ${this.state.currentIndex === 0 ? 'hide' : ''}` } onClick={ previousSlide } />
                    )}
                    renderCenterRightControls={({ nextSlide }) => (
                        <div className={ `carousel-control right-arrow ${(this.state.currentIndex + this.state.numberOfEvents) >= this.state.events.length ? 'hide' : ''}` } onClick={ nextSlide } />
                    )}
                >
                    {
                        Object.entries(this.state.events).map((e, i) => {
                            const date = e[1].start_date.split('-')
                            return <EventCarouselCard key={ i } event={ e } date={ date } />
                        })
                    }
                </Carousel>
            </div>
        )
    }
}

export { EventCarousel }
