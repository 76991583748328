export const time = {
    formatTime,
    getMonth,
    getDOW,
}

function formatTime(hours1, hours2, extraInfo) {
    if (extraInfo === 'All Day') return extraInfo

    if (hours1 && hours2) {
        const time1 = hours1.split(':')
        const time2 = hours2.split(':')
        let start, end
        if (Number(time1[0]) > 12) {
            start = `${Number(time1[0]) - 12}:${time1[1]}p`
        } else {
            start = `${time1[0]}:${time1[1]}a`
        }

        if (Number(time2[0]) > 12) {
            end = `${Number(time2[0]) - 12}:${time2[1]}p`
        } else {
            end = `${time2[0]}:${time2[1]}a`
        }

        return `${start} - ${end}`
    }
}

const months = {
    '01': 'January',
    '02': 'February',
    '03': 'March',
    '04': 'April',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'August',
    '09': 'September',
    '10': 'October',
    '11': 'November',
    '12': 'December'
}

function getMonth(number) {
    return months[number]
}

const days = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
}

function getDOW(date) {
    const day = new Date(date[2], date[0] - 1, date[1]).getDay()
    return days[day]
}
