import React, { Component } from 'react'
import '../../css/App.scss'
import { time } from '../../_helpers'
import { EventModal } from '../'
import Modal from 'react-modal'

class EventCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            eventList: this.props.eventList,
            date: this.props.date,
            modalIsOpen: false,
        }
    }

    componentWillReceiveProps(nextProps) {
        // this updates the state when it is changed in the parent element
        this.setState({
            eventList: nextProps.eventList,
            date: nextProps.date,
        })
    }

    openModal = (e) => {
        if (e.target.className === 'close') return
        this.setState({modalIsOpen: true})
    }

    closeModal = () => {
        this.setState({modalIsOpen: false})
    }

    checkToday(date) {
        const eventDate = new Date(date[2], date[0] - 1, date[1])
        const today = new Date(Date.now())
        if (today.getDate() === eventDate.getDate() && today.getMonth() === eventDate.getMonth() && today.getYear() === eventDate.getYear()) {
            return `See all of today's events`
        }
        return `See all of this day's events`
    }

    render() {
        const date = this.state.date.split('-')
        const featuredEvent = this.state.eventList.filter(e => e.featured_on.includes('calendar'))
        let firstEvent
        if (featuredEvent.length) {
            firstEvent = featuredEvent[0]
        } else {
            const randomNumber = Math.floor(Math.random() * this.state.eventList.length)
            firstEvent = this.state.eventList[randomNumber]
        }


        return (
            <div className={ this.state.eventList.length ? 'card event-card' : ' card event-card empty'} onClick={ this.state.eventList.length ? this.openModal : '' }>
                <div className="date">
                    <div className="month info">{ time.getMonth(date[0]).toUpperCase() }</div>
                    <div className="day title">{ Number(date[1]) }</div>
                </div>
                {
                    firstEvent ? (
                        <div className="first-event">
                            <div className="">
                                <div className="title">
                                    { firstEvent.name }
                                </div>
                                {
                                    firstEvent.repeat_text ? (
                                        <div className="hours">
                                            { firstEvent.repeat_text }
                                            <br />
                                            { time.formatTime(firstEvent.start_time, firstEvent.end_time, firstEvent.time_info) }
                                        </div>
                                    ) : (
                                        <div className="hours">
                                            { time.formatTime(firstEvent.start_time, firstEvent.end_time, firstEvent.time_info) }
                                        </div>
                                    )
                                }
                                {
                                    this.state.eventList.length > 1 ? (
                                        <div className="extra-events">
                                            { `+${this.state.eventList.length - 1} events` }
                                        </div>
                                    ) : ''
                                }
                            </div>
                            <div className="location info">
                                { firstEvent.location }
                            </div>
                            <Modal isOpen={ this.state.modalIsOpen } shouldCloseOnOverlayClick={ true } onRequestClose={ this.closeModal }>
                                <EventModal events={ this.state.eventList } modalIsOpen={ this.state.modalIsOpen } date={ date } close={ this.closeModal }/>
                            </Modal>
                        </div>
                    ) : ''
                }
                <div className={ this.state.eventList.length ? 'see-all' : 'hide'}>
                    { this.checkToday(date) } <div className="right-arrow green-arrow event-arrow"></div>
                </div>
            </div>
        )
    }
}





export { EventCard }
