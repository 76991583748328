import { formConstants } from '../_constants'
const initialState = { }

export function form(state = initialState, action) {
    switch (action.type) {
        case formConstants.SUBMIT_GREENSTREET_FORM_SUCCESS:
            return { ...state, newsletterMessage: action.message, newsletterError: null }
        case formConstants.SUBMIT_DISTRICT_FORM_SUCCESS:
            return { ...state, magazineMessage: action.message, magazineError: null }


        case formConstants.SUBMIT_GREENSTREET_FORM_FAILURE:
            return { ...state, newsletterError: action.error, newsletterMessage: null }
        case formConstants.SUBMIT_DISTRICT_FORM_FAILURE:
            return { ...state, magazineError: action.error, magazineMessage: null }


        case formConstants.CLEAR_MESSAGE:
            return { }
        default:
            return state
    }
}
