import { cookies } from '../_helpers'

export const pageServices = {
    getPage,
    getMap,
}

function getPage(pageSlug) {
    const requestOptions = {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
            'X-CSRFToken': cookies.getCookie('csrftoken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }

    return fetch(`/api/pages/?page=${pageSlug}`, requestOptions)
        .then(res => res.json())
        .then(data => data[0])
}

function getMap() {
    const requestOptions = {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
            'X-CSRFToken': cookies.getCookie('csrftoken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }

    return fetch(`/api/map/`, requestOptions)
        .then(res => res.json())
        .then(data => data[0])
}
