import { cookies } from '../_helpers'

export const formServices = {
    submitGreenStreet,
    submitDistrict,
}

function submitGreenStreet(data) {
    const requestOptions = {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
            'X-CSRFToken': cookies.getCookie('csrftoken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
    }

    return fetch(`/contact/greenstreet/`, requestOptions)
        .then(res => res.json())
        .then(data => data)
}

function submitDistrict(data) {
    const requestOptions = {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
            'X-CSRFToken': cookies.getCookie('csrftoken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
    }

    return fetch(`/contact/district/`, requestOptions)
        .then(res => res.json())
        .then(data => data)
}
