import { menuConstants } from '../_constants'
const initialState = { menuItems: [], activeMenu: false, showToDo: false, showNewsletter: false }

export function menu(state = initialState, action) {
    switch (action.type) {
        case menuConstants.GET_MENU_SUCCESS:
            return { ...state, menuItems: action.menuItems }
        case menuConstants.GET_MENU_ERROR:
            return { ...state, error: action.error }
        case menuConstants.TOGGLE_MENU:
            return {
                ...state,
                activeMenu: action.activeMenu,
                showToDo: action.showToDo
            }
        case menuConstants.TOGGLE_TODO_MENU:
            return { ...state, showToDo: action.showToDo }
        case menuConstants.TOGGLE_NEWSLETTER:
            return { ...state, showNewsletter: action.showNewsletter }
        default:
            return state
    }
}
