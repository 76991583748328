import { eventsConstants } from '../_constants'
import { eventsServices } from '../_services'

export const eventsActions = {
    getEventsList,
    getEvent,
    getEventsCarousel,
    getMoreEvents,
    getFilteredEvents,
    clearFilteredEvents,
}

function getEventsList() {
    return dispatch => {
        eventsServices.getEventsList()
            .then(
                events => dispatch(success(events)),
                error => dispatch(failure(error))
            )
    }
    function success(events) { return { type: eventsConstants.GET_EVENTS_SUCCESS, events } }
    function failure(error) { return { type: eventsConstants.GET_EVENTS_ERROR, error } }
}

function getEvent(id) {
    return dispatch => {
        eventsServices.getEvent(id)
            .then(
                singleEvent => dispatch(success(singleEvent)),
                error => dispatch(failure(error))
            )
    }
    function success(singleEvent) { return { type: eventsConstants.GET_EVENT_SUCCESS, singleEvent } }
    function failure(error) { return { type: eventsConstants.GET_EVENT_ERROR, error } }
}

function getEventsCarousel(page) {
    return dispatch => {
        eventsServices.getEventsCarousel(page)
            .then(
                events => dispatch(success(events)),
                error => dispatch(failure(error))
            )
    }
    function success(events) { return { type: eventsConstants.GET_EVENTS_CAROUSEL_SUCCESS, events, page } }
    function failure(error) { return { type: eventsConstants.GET_EVENTS_CAROUSEL_ERROR, error } }
}

function getMoreEvents(eventList, page) {
    page = page + 1
    return dispatch => {
        eventsServices.getMoreEvents(page)
            .then(
                events => {
                    for (let key in events) {
                        eventList[key] = events[key]
                    }
                    dispatch(success(eventList, page))
                },
                error => dispatch(failure(error))
            )
    }
    function success(events, pageNumber) { return { type: eventsConstants.GET_MORE_EVENTS_SUCCESS, events, pageNumber } }
    function failure(error) { return { type: eventsConstants.GET_MORE_EVENTS_ERROR, error } }
}

function getFilteredEvents(date) {
    return dispatch => {
        eventsServices.getFilteredEvents(date)
            .then(
                events => dispatch(success(events)),
                error => dispatch(failure(error))
            )
    }
    function success(events) { return { type: eventsConstants.GET_FILTERED_EVENTS_SUCCESS, events } }
    function failure(error) { return { type: eventsConstants.GET_FILTERED_EVENTS_ERROR, error } }
}

function clearFilteredEvents() {
    return dispatch =>  dispatch(clear())
    function clear() { return { type: eventsConstants.CLEAR_FILTERED_EVENTS } }
}
