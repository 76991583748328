import React, { Component } from 'react'
import { connect } from 'react-redux'
import { pageActions, magazineActions } from '../_actions'
import { NewsletterForm, Block } from './'
import ReactGA from 'react-ga'


class Magazine extends Component {
    constructor(props) {
        super(props)
        this.state = {
            magazinesCount: 6,
        }
    }

    componentWillReceiveProps(nextProps) {
        const currentPage = this.props.location.pathname;
        const nextPage = nextProps.location.pathname;

        if (currentPage !== nextPage && process.env.NODE_ENV !== 'development') {
            ReactGA.pageview(window.location.pathname)
        }
    }

    componentDidMount() {
        const { districtMagazine, dispatch, magazines } = this.props

        window.scrollTo(0, 0)

        if (!districtMagazine) {
            dispatch(pageActions.getPage('district-magazine'))
        }

        if (!magazines.length) {
            dispatch(magazineActions.getMagazines())
        }
    }

    render() {
        const { districtMagazine, magazines } = this.props
        const page = districtMagazine || {}
        const content = page.content || []
        let firstMagazine = {}
        if (magazines.length) {
            firstMagazine = magazines[0]
        }
        //break at 880px wide
        return (
            <div className="content" id="magazine">
                {
                    page.intro ? (
                        <div className="intro">
                            <p dangerouslySetInnerHTML={{ __html: page.intro }}></p>
                        </div>
                    ) : ''
                }
                <div className="blocks">
                    <div className="block left-block">
                        {
                            content.length ? (
                                content.filter(e => e.position === 'left').sort((a, b) => a.order > b.order ? 1 : -1 ).map((e, i) => (
                                    <Block key={ i } content={ e } />
                                ))
                            ) : ''
                        }
                    </div>
                    <div className="block right-block">
                        <NewsletterForm page="district-magazine" />
                    </div>
                </div>
                <div className="magazine-list">
                    <div className="current-magazine">
                        <div className="info">Latest Issue</div>
                        <div className="magazine">
                            <div className="magazine-image" style={{ backgroundImage: `url(${firstMagazine.cover})` }}/>
                            <div className="magazine-info">
                                <div className="title">{ firstMagazine.title }</div>
                                <a href={ firstMagazine.link } target="_blank" rel="noopener noreferrer"><div className="button">Read Issue</div></a>
                            </div>
                        </div>
                    </div>
                    <div className="old-magazines">
                        <div className="info">Past Issues</div>
                        <div className="old-magazines-list">
                            {
                                magazines.slice(1, this.state.magazinesCount + 1).map((e, i) => (
                                    <a key={ i } className="old-magazine" href={ e.link } target="_blank" rel="noopener noreferrer">
                                        <div className="magazine-image" style={{ backgroundImage: `url(${e.cover})` }}/>
                                        <div className="magazine-title">{ e.title }</div>
                                    </a>
                                ))
                            }
                        </div>
                        {
                            magazines.length > 6 ? <div className="button" onClick={ () => this.setState({ magazinesCount: magazines.length }) }>See All Past Issues</div> : ''
                        }
                    </div>
                </div>
                {
                    page.outro ? (
                        <div className="outro">
                            <p dangerouslySetInnerHTML={{ __html: page.outro }}></p>
                        </div>
                    ) : ''
                }
            </div>
        )
    }
}


function mapStateToProps(state) {
    const { districtMagazine } = state.page
    const { magazines } = state.magazine
    return {
        districtMagazine,
        magazines,
    }
}
const connectedMagazine = connect(mapStateToProps)(Magazine)
export { connectedMagazine as Magazine }
