import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import { menuActions, pageActions, formActions } from '../../_actions'
import { NewsletterForm } from '../'
import ReactGA from 'react-ga'


class Newsletter extends Component {
    componentWillReceiveProps(nextProps) {
        const currentPage = this.props.location.pathname;
        const nextPage = nextProps.location.pathname;

        if (currentPage !== nextPage && process.env.NODE_ENV !== 'development') {
            ReactGA.pageview(window.location.pathname)
        }
    }

    componentDidMount() {
        const { newsletter, dispatch } = this.props

        if (!newsletter) {
            dispatch(pageActions.getPage('newsletter'))
        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    close = () => {
        const { dispatch } = this.props
        dispatch(menuActions.toggleNewsletter(true))
        dispatch(formActions.clearMessage())
    }

    render() {
        const { showNewsletter, newsletter } = this.props
        const page = newsletter || {}

        return (
            <Modal className="newsletter" isOpen={ showNewsletter } shouldCloseOnOverlayClick={ true } onRequestClose={ this.closeModal }>
                <div id="newsletter">
                    <div className="close" onClick={ this.close }></div>
                    <div className="modal-content">
                        <div className="intro">
                            <p dangerouslySetInnerHTML={{ __html: page.intro }}></p>
                        </div>
                        <NewsletterForm page="newsletter" />
                    </div>
                </div>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    const { showNewsletter } = state.menu
    const { newsletter } = state.page
    return {
        showNewsletter,
        newsletter,
    }
}
const connectedNewsletter = connect(mapStateToProps)(Newsletter)
export { connectedNewsletter as Newsletter }
