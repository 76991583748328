import React, { Component } from 'react'

class PlaceListing extends Component {
    constructor(props) {
        super(props)
        this.state = {
            place: this.props.place,
            refProp: this.props.refProp,
        }
    }

    componentWillReceiveProps(nextProps) {
        // this updates the state when it is changed in the parent element
        this.setState({ place: nextProps.place, refProp: nextProps.refProp })
    }

    render() {
        const social = this.state.place.instagram_url || this.state.place.facebook_url || this.state.place.twitter_url
        return (
            <div className="place" ref={ this.state.refProp }>
                <div className="place-image" style={{ backgroundImage: `url(${this.state.place.thumbnail})` }}></div>
                <div className="place-info">
                    <h1 className="title place-title">{ this.state.place.name }</h1>
                    <p className="place-description" dangerouslySetInnerHTML={{ __html: this.state.place.description }}></p>
                    {
                        social ? (
                            <div className="place-social-icons">
                                {
                                    this.state.place.twitter_url ? (
                                        <a href={ this.state.place.twitter_url } target="_blank" rel="noopener noreferrer"><div className="twitter"/></a>
                                    ) : ''
                                }
                                {
                                    this.state.place.facebook_url ? (
                                        <a href={ this.state.place.twitter_url } target="_blank" rel="noopener noreferrer"><div className="facebook"/></a>
                                    ) : ''
                                }
                                {
                                    this.state.place.instagram_url ? (
                                        <a href={ this.state.place.twitter_url } target="_blank" rel="noopener noreferrer"><div className="instagram"/></a>
                                    ) : ''
                                }
                            </div>
                        ) : ''
                    }
                    {
                        this.state.place.website ? (
                            <p className="place-website"><a href={ this.state.place.website }>Visit Website</a></p>
                        ) : (
                            ''
                        )
                    }
                </div>
            </div>
        )
    }
}

export { PlaceListing }
