import React, { Component } from 'react'
import { connect } from 'react-redux'
import { pageActions, placeActions } from '../../_actions'
import { Map, Block } from '../'
import ReactGA from 'react-ga'


class Visit extends Component {
    componentWillReceiveProps(nextProps) {
        const currentPage = this.props.location.pathname;
        const nextPage = nextProps.location.pathname;

        if (currentPage !== nextPage && process.env.NODE_ENV !== 'development') {
            ReactGA.pageview(window.location.pathname)
        }
    }

    componentDidMount() {
        const { visit, dispatch, allPlaces, map } = this.props

        window.scrollTo(0, 0)

        if (!visit) {
            dispatch(pageActions.getPage('visit'))
        }

        if (!allPlaces.length) {
            dispatch(placeActions.getAllPlaces())
        }

        if (!map) {
            dispatch(pageActions.getMap('things-to-do'))
        }
    }

    render() {
        const { visit, allPlaces, map, menuItems } = this.props
        const page = visit || {}
        const content = page.content || []

        return (
            <div className="content" id="visit">
                <Map places={ allPlaces } map={ map || {} } categories={ menuItems }/>
                <div className="blocks">
                    <div className="block left-block">
                        {
                            content.length ? (
                                content.filter(e => e.position === 'left').sort((a, b) => a.order > b.order ? 1 : -1 ).map((e, i) => (
                                    <Block key={ i } content={ e } />
                                ))
                            ) : ''
                        }
                    </div>
                    <div className="block right-block">
                        {
                            content.length ? (
                                content.filter(e => e.position === 'right').sort((a, b) => a.order > b.order ? 1 : -1 ).map((e, i) => (
                                    <Block key={ i } content={ e } />
                                ))
                            ) : ''
                        }
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { visit, map } = state.page
    const { allPlaces } = state.places
    const { menuItems } = state.menu
    return {
        visit,
        allPlaces,
        map,
        menuItems,
    }
}
const connectedVisit = connect(mapStateToProps)(Visit)
export { connectedVisit as Visit }
