import { placeConstants } from '../_constants'
import { placeServices } from '../_services'

export const placeActions = {
    getPlaces,
    getAllPlaces,
}

function getPlaces(placeCategory) {
    return dispatch => {
        placeServices.getPlaces(placeCategory)
            .then(
                places => dispatch(success(places)),
                error => dispatch(failure(error))
            )
    }
    function success(places) { return { type: placeConstants.GET_PLACES_SUCCESS, category: placeCategory, places } }
    function failure(error) { return { type: placeConstants.GET_PLACES_FAILURE, error } }
}

function getAllPlaces() {
    return dispatch => {
        placeServices.getAllPlaces()
            .then(
                allPlaces => dispatch(success(allPlaces)),
                error => dispatch(failure(error))
            )
    }
    function success(allPlaces) { return { type: placeConstants.GET_ALL_PLACES_SUCCESS, allPlaces } }
    function failure(error) { return { type: placeConstants.GET_ALL_PLACES_FAILURE, error } }
}
