import React from 'react'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { loadAlert } from '../../_actions'
import { connect } from 'react-redux'

class Modal extends React.Component {
    state = {
        showModal: false
    }
    targetRef = React.createRef()
    targetElement = null

    componentDidMount() {
        setTimeout(() => {
            this.setState({ showModal: true })
        }, 500)
        this.targetElement = this.targetRef.current
        disableBodyScroll(this.targetElement)
        this.props.dispatch(loadAlert())
    }

    closeModal = () => {
        this.setState({ showModal: false })
        enableBodyScroll(this.targetElement)
    }

    componentWillUnmount() {
        clearAllBodyScrollLocks()
    }

    render() {
        const { alert } = this.props
        return (
            <div className={this.state.showModal ? 'modal-container' : 'modal-container hide'}>
                <div className='modal'>
                    <div className='modal-content'>
                        <div dangerouslySetInnerHTML={{__html: alert.modal_text}} />
                        <br />
                        <br />
                        <div className='modal-logo' />
                    </div>
                    <div title='Close (Esc)' className='modal-close' onClick={this.closeModal} />
                </div>
                <div ref={this.targetElement} onClick={this.closeModal} className='modal-background' />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        alert: state.alerts.alert
    }
}

const connectedModal = connect(mapStateToProps)(Modal)

export { connectedModal as Modal }
