import { pageConstants } from '../_constants'
const initialState = { activeMenu: false }

export function page(state = initialState, action) {
    switch (action.type) {
        case pageConstants.GET_PAGE_SUCCESS:
            return { ...state, [action.key]: action.page }
        case pageConstants.GET_MAP_SUCCESS:
            return { ...state, map: action.map }


        case pageConstants.GET_PAGE_FAILURE:
            return { ...state, error: action.error }
        case pageConstants.GET_MAP_FAILURE:
            return { ...state, error: action.error }


        default:
            return state
    }
}
