import React, { Component } from 'react'
import { connect } from 'react-redux'
import { pageActions, placeActions, eventsActions, menuActions } from '../../_actions'
import { ImageCarousel } from '../'
import { PlaceList, PlaceListing, EventCarousel } from '../'
import ReactGA from 'react-ga'


class Place extends Component {
    constructor(props) {
        super(props)
        this.state = {
            category: this.props.match.params.category,
            showMap: window.innerWidth > 800,
            showMapButton: window.innerWidth < 800,
        }
    }

    componentWillReceiveProps(nextProps) {
        const currentPage = this.props.location.pathname;
        const nextPage = nextProps.location.pathname;

        if (currentPage !== nextPage && process.env.NODE_ENV !== 'development') {
            ReactGA.pageview(window.location.pathname)
        }
    }

    componentDidMount() {
        const { thingsToDo, dispatch, places, eventCarousel, map } = this.props
        const category = this.state.category

        window.scrollTo(0, 0)

        if (!thingsToDo) {
            dispatch(pageActions.getPage('things-to-do'))
        }

        if (!map) {
            dispatch(pageActions.getMap('things-to-do'))
        }

        if (!places[category]) {
            dispatch(placeActions.getPlaces(category))
        }

        if (!eventCarousel.length) {
            dispatch(eventsActions.getEventsCarousel('things-to-do'))
        }
    }

    toggleMap = () => this.setState({ showMap: !this.state.showMap })

    openMenu = () => {
        const { activeMenu, showToDo, dispatch } = this.props
        dispatch(menuActions.toggleMenu(activeMenu))
        dispatch(menuActions.toggleToDo(showToDo))
    }

    scroll = (i) => {
        window.scroll({
            top: this[`ref${i}`].offsetTop - 70,
            behavior: 'smooth',
        })
    }

    render() {
        const { places, thingsToDo, eventCarousel, map } = this.props
        const placeList = places[this.state.category] ? places[this.state.category] : []
        const page = thingsToDo || {}
        return (
            <div className="content" id="place">
                <div className="head">
                    <div className="title">
                        <span className="page">{ page.title || 'Things To Do' } <div className="menu-divider" /></span>
                        <span className="category" onClick={ this.openMenu }>{ this.state.category.split('-').join(' ') }</span>
                    </div>
                </div>
                <ImageCarousel images={ placeList } page="things-to-do"/>
                <PlaceList places={ placeList } map={ map || {} } scroll={ this.scroll } show={ this.state.showMap } />
                <div className={ this.state.showMapButton ? 'toggle-map-button' : 'hide' }>
                    <div className="button" onClick={ this.toggleMap }>{ this.state.showMap ? 'Hide Map' : 'Show Map' }</div>
                </div>
                <div className="places-info-list">
                    {
                        placeList.map((e, i) => <PlaceListing key={ i } place={ e } refProp={ (c) => this[`ref${e.display_number}`] = c } />)
                    }
                </div>
                <EventCarousel events={ eventCarousel['things-to-do'] || {} }/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { thingsToDo, map } = state.page
    const { eventCarousel } = state.events
    const places = state.places

    return {
        thingsToDo,
        places,
        eventCarousel,
        map
    }
}
const connectedPlace = connect(mapStateToProps)(Place)
export { connectedPlace as Place }
