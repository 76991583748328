import React, { Component } from 'react'
import { connect } from 'react-redux'
import { pageActions } from '../_actions'
import { Block, ImageCarousel } from './'
import ReactGA from 'react-ga'


class Downtown extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showModal: false,
        }
    }

    componentWillReceiveProps(nextProps) {
        const currentPage = this.props.location.pathname;
        const nextPage = nextProps.location.pathname;

        if (currentPage !== nextPage && process.env.NODE_ENV !== 'development') {
            ReactGA.pageview(window.location.pathname)
        }
    }

    componentDidMount() {
        const { downtown, dispatch } = this.props

        window.scrollTo(0, 0)

        if (!downtown) {
            dispatch(pageActions.getPage('downtown'))
        }
    }

    openModal = () => this.setState({ showModal: true })
    closeModal = () => this.setState({ showModal: false })

    render() {
        const { downtown } = this.props
        const page = downtown || {}
        const content = page.content || []

        return (
            <div className="content" id="downtown">
                <div className="intro">
                    <div className="info downtown-title">{ page.title }</div>
                    <p dangerouslySetInnerHTML={{ __html: page.intro }}></p>
                </div>
                <ImageCarousel images={ page.thumbnails } page="downtown" />
                <div className="blocks">
                    <div className="block full-block">
                        {
                            content.length ? (
                                content.filter(e => e.position === 'full').sort((a, b) => a.order > b.order ? 1 : -1 ).map((e, i) => (
                                    <Block key={ i } content={ e } first={ i === 0 } />
                                ))
                            ) : ''
                        }
                    </div>
                </div>
                <div className="outro">
                    <p dangerouslySetInnerHTML={{ __html: page.outro }}></p>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { downtown } = state.page
    return {
        downtown
    }
}
const connectedDowntown = connect(mapStateToProps)(Downtown)
export { connectedDowntown as Downtown }
