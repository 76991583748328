import React, { Component } from 'react'
import '../../css/App.scss'

class ArticleModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            article: this.props.article,
            date: this.props.date,
            close: this.props.close,
        }
    }

    componentWillReceiveProps(nextProps) {
        // this updates the state when it is changed in the parent element
        this.setState({
            article: nextProps.article,
            date: nextProps.date,
        })
    }

    render() {
        const URL = `${encodeURIComponent(window.location.href)}/?article=${this.state.article.slug}`
        const facebookURL = `https://www.facebook.com/sharer/sharer.php?u=${URL}`
        const twitterURL = `https://twitter.com/share?url=${URL}`

        return (
            <div className="modal">
                <meta property="og:url" content="http://www.nytimes.com/2015/02/19/arts/international/when-great-minds-dont-think-alike.html" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={ this.state.article.title } />
                <meta property="og:description" content="How much does culture influence creative thinking?" />
                <meta property="og:image" content={ this.state.article.thumbnail } />

                <div className="close" onClick={ this.state.close }></div>

                <div className="article-modal">
                    <div className="article-title">
                        <h1 className="date info">{ this.state.date }</h1>
                        <h1 className="title">{ this.state.article.title }</h1>
                        <div className="author-share">
                            <p className="author">{ this.state.article.author ? this.state.article.author.name : '' }</p>
                            <div className="share">
                                <a href={ `mailto:?&subject=${this.state.article.title}&body=${URL}` }>
                                    <div className="email" />
                                </a>
                                <a href={ twitterURL } target="_blank" rel="noopener noreferrer">
                                    <div className="twitter"/>
                                </a>
                                <a href={ facebookURL } target="_blank" rel="noopener noreferrer">
                                    <div className="facebook" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="article-content">
                        {
                            this.state.article.thumbnail ? (
                                <div className="article-modal-image" style={{ backgroundImage: `url(${this.state.article.thumbnail})` }} />
                            ) : ''
                        }
                        <div className="article" dangerouslySetInnerHTML={{ __html: this.state.article.content }}/>
                    </div>
                </div>
            </div>
        )
    }
}

export { ArticleModal }
