import { articlesConstants } from '../_constants'
import { articlesServices } from '../_services'

export const articleActions = {
    getArticles,
    getArticle,
    getMoreArticles,
}

function getArticles() {
    return dispatch => {
        articlesServices.getArticles()
            .then(
                articles => dispatch(success(articles)),
                error => dispatch(failure(error))
            )
    }
    function success(articles) { return { type: articlesConstants.GET_ARTICLES_SUCCESS, articles } }
    function failure(error) { return { type: articlesConstants.GET_ARTICLES_ERROR, error } }
}

function getArticle(slug) {
    return dispatch => {
        articlesServices.getArticle(slug)
            .then(
                article => dispatch(success(article)),
                error => dispatch(failure(error))
            )
    }
    function success(article) { return { type: articlesConstants.GET_ARTICLE_SUCCESS, article } }
    function failure(error) { return { type: articlesConstants.GET_ARTICLES_ERROR, error } }
}

function getMoreArticles(articleList, page) {
    page = page + 1
    return dispatch => {
        articlesServices.getMoreArticles(page)
            .then(
                articles => {
                    for (let key in articles) {
                        articleList[key] = articles[key]
                    }
                    dispatch(success(articleList, page))
                },
                error => dispatch(failure(error))
            )
    }
    function success(articles, page) { return { type: articlesConstants.GET_MORE_ARTICLES_SUCCESS, articles, page } }
    function failure(error) { return { type: articlesConstants.GET_MORE_ARTICLES_ERROR, error } }
}
