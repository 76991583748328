import { magazineConstants } from '../_constants'
const initialState = { magazines: [] }

export function magazine(state = initialState, action) {
    switch (action.type) {
        case magazineConstants.GET_MAGAZINES_SUCCESS:
            return { ...state, magazines: action.magazines }
        case magazineConstants.GET_MAGAZINES_ERROR:
            return { ...state, error: action.error }
        default:
            return state
    }
}
