import { menuConstants } from '../_constants'
import { menuServices } from '../_services'

export const menuActions = {
    toggleMenu,
    getMenuList,
    toggleToDo,
    toggleNewsletter,
}

function toggleMenu(activeMenu) {
    return dispatch => dispatch(toggledMenu(!activeMenu))
    function toggledMenu(activeMenu) { return { type: menuConstants.TOGGLE_MENU, activeMenu, showToDo: false } }
}

function toggleToDo(showToDo) {
    return dispatch => dispatch(toggledMenu(!showToDo))
    function toggledMenu(showToDo) { return { type: menuConstants.TOGGLE_TODO_MENU, showToDo } }
}

function toggleNewsletter(showNewsletter) {
    return dispatch => dispatch(toggledMenu(!showNewsletter))
    function toggledMenu(showNewsletter) { return { type: menuConstants.TOGGLE_NEWSLETTER, showNewsletter } }
}

function getMenuList() {
    return dispatch => {
        menuServices.getMenuList()
            .then(
                menu => dispatch(success(menu)),
                error => dispatch(failure(error))
            )
    }
    function success(menuItems) { return { type: menuConstants.GET_MENU_SUCCESS, menuItems } }
    function failure(error) { return { type: menuConstants.GET_MENU_FAILURE, error } }
}
