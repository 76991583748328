import { formConstants } from '../_constants'
import { formServices } from '../_services'

export const formActions = {
    submitGreenStreet,
    submitDistrict,
    clearMessage,
}

function submitGreenStreet(formSlug) {
    return dispatch => {
        formServices.submitGreenStreet(formSlug)
            .then(
                message => {
                    if (message.error) return dispatch(failure(message.error))
                    return dispatch(success(message.message))
                },
                error => dispatch(failure(error))
            )
    }
    function success(message) { return { type: formConstants.SUBMIT_GREENSTREET_FORM_SUCCESS, message } }
    function failure(error) { return { type: formConstants.SUBMIT_GREENSTREET_FORM_FAILURE, error } }
}

function submitDistrict(formSlug) {
    return dispatch => {
        formServices.submitDistrict(formSlug)
            .then(
                message => {
                    if (message.error) return dispatch(failure(message.error))
                    return dispatch(success(message.message))
                },
                error => dispatch(failure(error))
            )
    }
    function success(message) { return { type: formConstants.SUBMIT_DISTRICT_FORM_SUCCESS, message } }
    function failure(error) { return { type: formConstants.SUBMIT_DISTRICT_FORM_FAILURE, error } }
}

function clearMessage() {
    return dispatch => dispatch(clear())
    function clear() { return { type: formConstants.CLEAR_MESSAGE } }
}
