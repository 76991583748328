import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { history } from './_helpers'
import {
    About,
    Calendar,
    Downtown,
    Footer,
    Header,
    Home,
    Leasing,
    Magazine,
    Menu,
    News,
    Newsletter,
    NotFound,
    Place,
    Visit,
    Modal as CancelModal,
} from './components'
import './css/normalize.css'
import 'react-dates/lib/css/_datepicker.css'
import './css/App.scss'
import colors from './css/colors.scss'
import { menuActions, eventsActions, loadAlert } from './_actions'
import { errorServices } from './_services'
import 'react-dates/initialize'
import Modal from 'react-modal'
import ReactGA from 'react-ga'

class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            GAStarted: false
        }
    }

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(menuActions.getMenuList())

        if (!this.state.GAStarted) {
            // ReactGA.initialize('UA-39852622-1', { debug: false })
            Modal.setAppElement('#root')
        }
        // dispatch(loadAlert())
    }

    componentDidUpdate() {
        const { dispatch } = this.props
        if (history.location.pathname !== '/calendar') {
            dispatch(eventsActions.clearFilteredEvents())
        }

        window.onpopstate = () => {
            dispatch(menuActions.toggleMenu(true))
        }
    }

    componentDidCatch(error, info) {
        errorServices.sendError(error.message, info.componentStack, window.location.href)
    }

    static getDerivedStateFromError() {
        // go to error page
    }

    render() {
        const { activeMenu, alert } = this.props
        document.body.style.cssText = `background-color: ${activeMenu ? colors.magenta : colors.green}`
        return (
            <Router history={history}>
                <div>
                    <Route path="*" component={Header} />
                    {
                        activeMenu ? (
                            <Route path="*" component={Menu} />
                        ) : (
                                <Switch>
                                    <Route path="/about" component={About} />
                                    <Route path="/calendar" component={Calendar} />
                                    <Route path="/magazine" component={Magazine} />
                                    <Route path="/downtown" component={Downtown} />
                                    <Route path="/leasing" component={Leasing} />
                                    <Route path="/news" component={News} />
                                    <Route path="/places/:category" component={Place} />
                                    <Route path="/visit" component={Visit} />
                                    <Route exact path="/" component={Home} />
                                    <Route path="*" component={NotFound} />
                                </Switch>
                            )
                    }
                    <Route path="*" component={Footer} />
                    <Route path="*" component={Newsletter} />
                    {alert.show_modal ? <Route path="*" component={CancelModal} /> : ''}
                </div>
            </Router>
        )
    }
}

function mapStateToProps(state) {
    const { activeMenu } = state.menu
    const { alert } = state.alerts
    return {
        activeMenu,
        alert,
    }
}

const connectedApp = connect(mapStateToProps)(App)
export { connectedApp as App }
