import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { menuActions } from '../../_actions'

class Menu extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    showToDo = () => {
        const { showToDo, dispatch } = this.props
        dispatch(menuActions.toggleToDo(showToDo))
    }

    closeMenu = () => {
        const { activeMenu, dispatch } = this.props
        dispatch(menuActions.toggleMenu(activeMenu))
    }

    render() {
        const { activeMenu, menuItems, showToDo } = this.props
        const menuCSS = activeMenu ? 'title' : 'hide title'
        return (
            <div id="menu" className={ menuCSS }>
                <div onClick={ this.showToDo } id="things-to-do-menu-header">Things To Do</div>
                <div id="things-to-do-menu" className={ showToDo ? 'active' : 'hide-menu' }>
                    {
                        menuItems.map((e, i) => <Link key={ i } to={ `/places/${e.slug}` } onClick={ this.closeMenu }>{ e.name }</Link>)
                    }
                </div>
                <div>
                    <Link to="/visit" onClick={ this.closeMenu }>Visit</Link>
                </div>
                <div>
                    <Link to="/calendar" onClick={ this.closeMenu }>Calendar</Link> / <Link to="/news" onClick={ this.closeMenu }>News</Link>
                </div>
                <div>
                    <Link to="/about" onClick={ this.closeMenu }>About</Link>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { activeMenu, menuItems, showToDo } = state.menu
    return {
        activeMenu,
        menuItems,
        showToDo
    }
}

const connectedMenu = connect(mapStateToProps)(Menu)
export { connectedMenu as Menu }
