import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { menuActions, formActions } from '../../_actions'

class Footer extends Component {
    closeMenu = () => {
        const { dispatch } = this.props
        dispatch(menuActions.toggleMenu(true))
    }

    showNewsletter = () => {
        const { showNewsletter, dispatch } = this.props
        dispatch(menuActions.toggleNewsletter(showNewsletter))
        dispatch(formActions.clearMessage())
    }

    render() {
        return (
            <div id="footer">
                <div id="footer-info">
                    <div id="footer-logo"></div>
                    <div id="footer-links">
                        <Link to="/leasing" className="info" onClick={ this.closeMenu }>
                            LEASING
                        </Link>
                        <Link to="/downtown" className="info" onClick={ this.closeMenu }>
                            DOWNTOWN
                        </Link>
                        <Link to="/magazine" className="info" onClick={ this.closeMenu }>
                            DISTRICT MAGAZINE
                        </Link>
                        <div className="newsletter-link info" onClick={ this.showNewsletter }>
                            NEWSLETTER
                        </div>
                    </div>
                </div>
                <div id="footer-copyright">
                    <a href="http://midwaycompanies.com/" target="_blank" rel="noopener noreferrer"><div id="midway-logo"></div></a>
                    <div id="copyright"  className="cp-desktop">&copy;{ (new Date()).getFullYear() } GreenStreet</div>
                </div>
                <div id="footer-contact">
                    <div id="footer-address">
                        1201 Fannin Street <br />
                        Houston, Texas 77002 <br />
                        <a id="footer-phone" href="tel:8323201201">832.320.1201</a>
                    </div>
                    <a id="footer-email" href="mailto:development@mwm.partners">info@greenstreetdowntown.com</a>
                </div>
                <div className="cp-mobile">
                    <div id="copyright" >&copy;{ (new Date()).getFullYear() } GreenStreet</div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { showNewsletter } = state.menu
    return {
        showNewsletter
    }
}
const connectedFooter = connect(mapStateToProps)(Footer)
export { connectedFooter as Footer }
