import { articlesConstants } from '../_constants'
const initialState = { articles: [], pageNumber: 1, article: {} }

export function articles(state = initialState, action) {
    switch (action.type) {
        case articlesConstants.GET_ARTICLES_SUCCESS:
            return { ...state, articles: action.articles, pageNumber: 1 }
        case articlesConstants.GET_ARTICLE_SUCCESS:
            return { ...state, article: action.article }
        case articlesConstants.GET_MORE_ARTICLES_SUCCESS:
            return { ...state, articles: action.articles, pageNumber: action.pageNumber }


        case articlesConstants.GET_ARTICLES_ERROR:
            return { ...state, error: action.error }
        case articlesConstants.GET_ARTICLE_ERROR:
            return { ...state, error: action.error }
        case articlesConstants.GET_MORE_ARTICLES_ERROR:
            return { ...state, error: action.error }
        default:
            return state
    }
}
