import React, { Component } from 'react'
import '../../css/App.scss'
import { time } from '../../_helpers'
import { ArticleModal } from '../'
import Modal from 'react-modal'

class ArticleCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            article: this.props.article,
            modalIsOpen: false,
            first: this.props.first,
        }
    }

    componentWillReceiveProps(nextProps) {
        // this updates the state when it is changed in the parent element
        this.setState({
            article: nextProps.article,
            first: nextProps.first,
         })
    }

    openModal = (e) => {
        if (e.target.className === 'close') return
        this.setState({modalIsOpen: true})
    }

    closeModal = () => this.setState({modalIsOpen: false})

    render() {
        const date = this.state.article.dateline.split('-')
        const dateText =  `${time.getMonth(date[1])} ${date[2]}, ${date[0]}`
        return (
            <div className={ this.state.article.thumbnail && this.state.first ? 'card article-card first-card' : 'card article-card' } onClick={ this.openModal }>
                <div className="card-info">
                    <div className="date">
                        <div className="info">{ dateText }</div>
                    </div>
                    <div className="article-info">
                        <div className="title">
                            { this.state.article.title }
                        </div>
                        <div className="author info">
                            { this.state.article.author.name }
                        </div>
                    </div>
                    <Modal isOpen={ this.state.modalIsOpen } shouldCloseOnOverlayClick={ true } onRequestClose={ this.closeModal }>
                        <ArticleModal
                            article={ this.state.article }
                            modalIsOpen={ this.state.modalIsOpen }
                            date={ dateText } close={ this.closeModal }
                        />
                    </Modal>
                    <div className="read-article">
                        Read the article <div className="right-arrow green-arrow event-arrow"></div>
                    </div>
                </div>
                <div className={ this.state.article.thumbnail && this.state.first ? 'card-image' : 'hidden' } style={{ backgroundImage: `url(${this.state.article.thumbnail})` }}></div>
            </div>
        )
    }
}

export { ArticleCard }
