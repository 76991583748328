import React, { Component } from 'react'

class LocationMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filter: this.props.filter,
            showSelect: this.props.showSelect,
            categories: this.props.categories,
            changeFilter: this.props.changeFilter,
            toggleSelectMenu: this.props.toggleSelectMenu,
            hide: this.props.hide,
            places: this.props.places,
        }
    }

    componentWillReceiveProps(nextProps) {
        // this updates the state when it is changed in the parent element
        this.setState({
            filter: nextProps.filter,
            showSelect: nextProps.showSelect,
            categories: nextProps.categories,
            changeFilter: nextProps.changeFilter,
            toggleSelectMenu: nextProps.toggleSelectMenu,
            hide: nextProps.hide,
            places: nextProps.places
        })
    }


    render() {
        let categories = this.state.categories
        if (this.state.filter.placeString) {
            categories = this.state.categories.filter(e => this.state.filter.placeString.match(e.slug))
        }

        return (
            <div className={ this.state.hide ? 'hide' : 'location-list-menu' }>
                <div className="title" onClick={ this.state.toggleSelectMenu }>
                    { this.state.filter.name }
                    <div className={ this.state.showSelect ? 'menu-arrow' : 'menu-arrow menu-arrow-right' }/>
                </div>
                <div className={ this.state.showSelect ? 'filter-options' : 'hide' }>
                    <ul>
                        <li key={ 0 }
                            name="All"
                            slug=""
                            className={ this.state.filter.name === 'All' ? 'hide' : 'title' }
                            onClick={ this.state.changeFilter }
                        >
                            All
                        </li>
                        {
                            categories.map((e, i) => (
                                <li key={ i + 1 }
                                    name={ e.name }
                                    slug={ e.slug }
                                    filter={ this.state.filter.name }
                                    className={ this.state.filter.name === e.name ? 'hide' : 'title' }
                                    onClick={ this.state.changeFilter }
                                >
                                    { e.name }
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        )
    }
}

export { LocationMenu }
