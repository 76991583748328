import React, { Component } from 'react'
import ReactPlayer from 'react-player'

class VideoModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showModal: this.props.showModal,
            url: this.props.url,
            closeModal: this.props.closeModal,
            videoNumber: this.props.videoNumber,
            playing: true,
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            showModal: nextProps.showModal,
            url: nextProps.url,
            videoNumber: nextProps.videoNumber,
        })
    }

    render() {
        return (
            <div className={ this.state.showModal ? 'video-modal' : 'hide' }>
                <div className="close-container">
                    <div className="close-video" onClick={ () => this.state.closeModal(this.state.videoNumber) }>&#xd7;</div>
                </div>
                <ReactPlayer url={ this.state.url } playing={ this.state.showModal } height="100vh" width="100vw" />
            </div>
        )
    }
}

export { VideoModal }
