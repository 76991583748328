import { placeConstants } from '../_constants'
const initialState = { allPlaces: [] }

export function places(state = initialState, action) {
    switch (action.type) {
        case placeConstants.GET_PLACES_SUCCESS:
            return { ...state, [action.category]: action.places }
        case placeConstants.GET_ALL_PLACES_SUCCESS:
            return { ...state, allPlaces: action.allPlaces }


        case placeConstants.GET_PLACES_FAILURE:
            return { ...state, error: action.error }
        case placeConstants.GET_ALL_PLACES_FAILURE:
            return { ...state, error: action.error }
        default:
            return state
    }
}
