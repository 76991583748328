import React, { Component } from 'react'
import '../../css/App.scss'
import { EventModal } from '../'
import Modal from 'react-modal'
import { time } from '../../_helpers'

class EventCarouselCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            date: this.props.date,
            event: this.props.event,
            modalIsOpen: false,
        }
    }

    componentWillReceiveProps(nextProps) {
        // this updates the state when it is changed in the parent element
        this.setState({
            event: nextProps.event,
            date: nextProps.date,
        })
    }

    openModal = (e) => {
        if (e.target.className === 'close') return
        this.setState({modalIsOpen: true})
    }

    closeModal = () => {
        this.setState({modalIsOpen: false})
    }

    truncate = (description) => {
        const length = 20
        const descriptionArr = description.split(' ')
        if (descriptionArr.length > length) {
            return `${descriptionArr.slice(0, length).join(' ')}...`
        } else {
            return description
        }
    }

    render() {
        return (
            <div className="event-info" onClick={ this.openModal }>
                <h1 className="date title">
                    { `${time.getMonth(this.state.date[0])} ${Number(this.state.date[1])}` }
                </h1>
                {
                    this.state.event[1].repeat_text ? (
                        <p className="time">
                            { this.state.event[1].repeat_text }
                            <br />
                            { time.formatTime(this.state.event[1].start_time, this.state.event[1].end_time, this.state.event[1].time_info) }
                        </p>
                    ) : (
                        <p className="time">
                            { time.formatTime(this.state.event[1].start_time, this.state.event[1].end_time, this.state.event[1].time_info) }
                        </p>
                    )
                }
                <h1 className="event-title sub-title">{ this.state.event[1].name }</h1>
                <p className="description" dangerouslySetInnerHTML={{ __html: this.truncate(this.state.event[1].description) }}></p>
                <Modal isOpen={ this.state.modalIsOpen } shouldCloseOnOverlayClick={ true } onRequestClose={ this.closeModal }>
                    <EventModal events={ [this.state.event[1]] } modalIsOpen={ this.state.modalIsOpen } date={ this.state.date } close={ this.closeModal }/>
                </Modal>
            </div>
        )
    }
}

export { EventCarouselCard }
