export const articlesConstants = {
    GET_ARTICLES_SUCCESS: 'GET_ARTICLES_SUCCESS',
    GET_ARTICLE_SUCCESS: 'GET_ARTICLE_SUCCESS',
    GET_MORE_ARTICLES_SUCCESS: 'GET_MORE_ARTICLES_SUCCESS',


    GET_ARTICLES_ERROR: 'GET_ARTICLES_ERROR',
    GET_ARTICLE_ERROR: 'GET_ARTICLE_ERROR',
    GET_MORE_ARTICLES_ERROR: 'GET_MORE_ARTICLES_ERROR',
}
