import { alertTypes } from '../_constants'

const initialState = { alert: {}, }

export function alerts(state = initialState, action) {
    switch (action.type) {
        case alertTypes.ALERT_SUCCESS:
            return {...state, alert: action.payload}
        default:
            return state
    }
}