import React, { Component } from 'react'
import { connect } from 'react-redux'
import { pageActions, formActions } from '../../_actions'
import { forms } from '../../_helpers'

class NewsletterForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: this.props.page,
            formControls: {
                name: {
                    value: '',
                    valid: false,
                    touched: false,
                },
                email: {
                    value: '',
                    valid: false,
                    touched: false,
                    validationRules: {
                        isEmail: true,
                    },
                },
                zip: {
                    value: '',
                    valid: false,
                    touched: false,
                    validationRules: {
                        minlength: 5,
                        maxlength: 5,
                        number: true,
                    },
                },
                state: {
                    value: '',
                    valid: false,
                    touched: false,
                    options: [ ...forms.states ],
                },
                address: {
                    value: '',
                    valid: false,
                    touched: false,
                },
                city: {
                    value: '',
                    valid: false,
                    touched: false,
                },
                comments: {
                    value: '',
                    valid: true,
                    touched: false,
                },
                extraSubscribe: {
                    valid: true,
                    value: false,
                },
            }
        }
    }

    componentDidMount() {
        const { newsletter, dispatch } = this.props

        window.scrollTo(0, 0)

        if (!newsletter) {
            dispatch(pageActions.getPage('newsletter'))
        }
    }

    handleChange = (event) => {
        const name = event.target.name
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
        const updatedControls = { ...this.state.formControls }
        const updatedFormElement = { ...updatedControls[name] }

        updatedFormElement.value = value
        updatedFormElement.touched = true
        updatedFormElement.valid = forms.validate(value, updatedFormElement.validationRules)
        updatedControls[name] = updatedFormElement

        this.setState({ formControls: updatedControls })
    }

    handleSubmit = (e) => {
        e.preventDefault();

        const { dispatch } = this.props

        for (let key in this.state.formControls) {
            if (this.state.page === 'newsletter' && (key === 'state' || key === 'address' || key === 'city')) continue
            if (!this.state.formControls[key].valid) return
        }
        if (this.state.page === 'newsletter') {
            dispatch(formActions.submitGreenStreet(this.state.formControls))
        } else {
            if (this.state.formControls.extraSubscribe.value) {
                dispatch(formActions.submitGreenStreet(this.state.formControls))
            }
            dispatch(formActions.submitDistrict(this.state.formControls))
        }
    }

    render() {
        const { magazineError, magazineMessage, newsletterError, newsletterMessage } = this.props
        let validForm = this.state.formControls.name.valid &&
                    this.state.formControls.email.valid &&
                    this.state.formControls.zip.valid
        if (this.state.page === 'district-magazine') {
            validForm = validForm && this.state.formControls.state.valid
        }
        const validName = this.state.formControls.name.touched ? this.state.formControls.name.valid : true
        const validEmail = this.state.formControls.email.touched ? this.state.formControls.email.valid : true
        const validState = this.state.formControls.state.touched ? this.state.formControls.state.valid : true
        const validZip = this.state.formControls.zip.touched ? this.state.formControls.zip.valid : true

        return (
            <form onSubmit={ this.handleSubmit } className="newsletter-form" autoComplete="on">
                <h1 className="info">Subscribe</h1>
                <label className={ validName ? '' : 'error' }>
                    <input type="text" name="name" value={ this.state.formControls.name.value } onChange={ this.handleChange } autoComplete="name"/>
                    <span>Name</span>
                </label>
                <label className={ validEmail ? '' : 'error' }>
                    <input type="text" name="email" value={ this.state.formControls.email.value } onChange={ this.handleChange } autoComplete="email"/>
                    <span>Email</span>
                </label>
                {
                    this.state.page === 'district-magazine' ? (
                        <label className={ validState ? '' : 'error' }>
                            <input type="text" name="address" value={ this.state.formControls.address.value } onChange={ this.handleChange } autoComplete="street-address"/>
                            <span>Address</span>
                        </label>
                    ) : ''
                }
                {
                    this.state.page === 'district-magazine' ? (
                        <label className={ validState ? '' : 'error' }>
                            <input type="text" name="city" value={ this.state.formControls.city.value } onChange={ this.handleChange } autoComplete="address-level2"/>
                            <span>City</span>
                        </label>
                    ) : ''
                }
                <label className="form-location">
                    {
                        this.state.page === 'district-magazine' ? (
                            <label className={ validState ? '' : 'error' }>
                                <select type="text" name="state" value={ this.state.formControls.state.value } onChange={ this.handleChange } autoComplete="address-level1">
                                    <option value=""></option>
                                    {
                                        this.state.formControls.state.options.map((e, i) => (
                                            <option key={ i } value={ e }>{ e }</option>
                                        ))
                                    }
                                </select>
                                <span>State</span>
                            </label>
                        ) : ''
                    }
                    <label className={ validZip ? '' : 'error' }>
                        <input type="text" name="zip" value={ this.state.formControls.zip.value } onChange={ this.handleChange } autoComplete="postal-code" />
                        <span>Zip Code</span>
                    </label>
                </label>
                {
                    this.state.page === 'district-magazine' ? (
                        <label className="checkbox">
                            <input type="checkbox" name="extraSubscribe" checked={ this.state.formControls.extraSubscribe.value } onChange={ this.handleChange } />
                            <span className="checkmark"></span>
                            <span>Subscribe me to the GreenStreet newsletter as well</span>
                        </label>
                    ) : ''
                }
                <label>
                    <textarea type="text" name="comments" value={ this.state.formControls.comments.value } onChange={ this.handleChange } />
                    <span>Comments</span>
                </label>

                <div className={ magazineMessage ? 'form-message' : 'hide' }>{ magazineMessage }</div>
                <div className={ magazineError ? 'form-error' : 'hide' }>{  typeof magazineError === 'string' ? magazineError : 'There was an error processing your request, please try again later.' }</div>
                <br />
                <div className={ newsletterMessage ? 'form-message' : 'hide' }>{ newsletterMessage }</div>
                <div className={ newsletterError ? 'form-error' : 'hide' }>{  typeof newsletterError === 'string' ? newsletterError : 'There was an error processing your request, please try to subscribe to the GreenStreet newsletter again later.' }</div>

                <button type="submit" className={ validForm ? '' : 'disabled-submit'}>
                    Submit + Subscribe <div className={ this.state.page === 'district-magazine' ? 'right-arrow' : 'right-arrow green-arrow' } />
                </button>
            </form>
        )
    }
}

function mapStateToProps(state) {
    const { showNewsletter } = state.menu
    const { newsletter } = state.page
    const { magazineError, magazineMessage, newsletterError, newsletterMessage } = state.form
    return {
        showNewsletter,
        newsletter,
        magazineError,
        magazineMessage,
        newsletterError,
        newsletterMessage,
    }
}
const connectedNewsletterForm = connect(mapStateToProps)(NewsletterForm)
export { connectedNewsletterForm as NewsletterForm }
