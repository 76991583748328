import React, { Component } from 'react'
import { connect } from 'react-redux'
import { pageActions } from '../_actions'
import { Block, ImageCarousel } from './'
import ReactGA from 'react-ga'


class Leasing extends Component {
    componentWillReceiveProps(nextProps) {
        const currentPage = this.props.location.pathname;
        const nextPage = nextProps.location.pathname;

        if (currentPage !== nextPage && process.env.NODE_ENV !== 'development') {
            ReactGA.pageview(window.location.pathname)
        }
    }

    componentDidMount() {
        const { leasing, dispatch } = this.props

        window.scrollTo(0, 0)

        if (!leasing) {
            dispatch(pageActions.getPage('leasing'))
        }
    }

    render() {
        const { leasing } = this.props
        const page = leasing || {}
        const content = page.content || []

        return (
            <div className="content" id="leasing">
                {
                    page.intro ? (
                        <div className="intro">
                            <p dangerouslySetInnerHTML={{ __html: page.intro }}></p>
                        </div>
                    ) : ''
                }
                <div className="blocks">
                    <div className="block left-block">
                        {
                            content.length ? (
                                content.filter(e => e.position === 'left').sort((a, b) => a.order > b.order ? 1 : -1 ).map((e, i) => (
                                    <Block key={ i } content={ e } />
                                ))
                            ) : ''
                        }
                    </div>
                    <div className="block right-block">
                        {
                            content.length ? (
                                content.filter(e => e.position === 'right').sort((a, b) => a.order > b.order ? 1 : -1 ).map((e, i) => (
                                    <Block key={ i } content={ e } />
                                ))
                            ) : ''
                        }
                    </div>
                </div>
                <ImageCarousel images={ page.thumbnails } page="about" />
                <div className="blocks">
                    <div className="block full-block">
                        {
                            content.length ? (
                                content.filter(e => e.position === 'full').sort((a, b) => a.order > b.order ? 1 : -1 ).map((e, i) => (
                                    <Block key={ i } content={ e } first={ i === 0 } />
                                ))
                            ) : ''
                        }
                    </div>
                </div>
                {
                    page.outro ? (
                        <div className="outro">
                            <p dangerouslySetInnerHTML={{ __html: page.outro }}></p>
                        </div>
                    ) : ''
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { leasing } = state.page
    return {
        leasing
    }
}
const connectedLeasing = connect(mapStateToProps)(Leasing)
export { connectedLeasing as Leasing }
