import React, { Component } from 'react'
import { connect } from 'react-redux'
import { pageActions, eventsActions } from '../_actions'
import { EventCarousel, ImageCarousel } from './'
import '../css/App.scss'
import ReactGA from 'react-ga'


class Home extends Component {
    eventCarouselRef = React.createRef()

    componentWillReceiveProps(nextProps) {
        const currentPage = this.props.location.pathname;
        const nextPage = nextProps.location.pathname;

        if (currentPage !== nextPage && process.env.NODE_ENV !== 'development') {
            ReactGA.pageview(window.location.pathname)
        }
    }

    componentDidMount() {
        const { home, dispatch, eventCarousel } = this.props

        window.scrollTo(0, 0)

        if (!home) {
            dispatch(pageActions.getPage('home'))
        }

        if (!eventCarousel['home']) {
            dispatch(eventsActions.getEventsCarousel('home'))
        }
    }

    truncate = (description) => {
        const length = 100
        if (description.length > length) {
            return `${description.substring(0, length)}...`
        } else {
            return description
        }
    }

    scrollDown = () => {
        window.scrollTo({
            top: this.eventCarouselRef.current.offsetTop - 70,
            behavior: 'smooth'
        })
    }
    render() {
        const { home, eventCarousel } = this.props
        const page = home || {}

        return (
            <div className="content" id="home">
                <ImageCarousel images={ page.thumbnails } page="home" autoPlay={ true } />
                <div className="down-arrow scroll-down" onClick={ this.scrollDown }></div>
                {
                    page.intro ? (
                        <div className="intro">
                            <p dangerouslySetInnerHTML={{ __html: page.intro }}></p>
                        </div>
                    ) : ''
                }
                <div ref={ this.eventCarouselRef }>
                    <EventCarousel events={ eventCarousel['home'] || {} } />
                </div>
                {
                    page.outro ? (
                        <div className="outro">
                            <p dangerouslySetInnerHTML={{ __html: page.outro }}></p>
                        </div>
                    ) : ''
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { home } = state.page
    const { eventCarousel } = state.events
    return {
        home,
        eventCarousel,
    }
}
const connectedHome = connect(mapStateToProps)(Home)
export { connectedHome as Home }
