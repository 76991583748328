import { cookies } from '../_helpers'

export const eventsServices = {
    getEventsList,
    getEvent,
    getEventsCarousel,
    getMoreEvents,
    getFilteredEvents,
}

function getEventsList() {
    const requestOptions = {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
            'X-CSRFToken': cookies.getCookie('csrftoken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }

    return fetch('/api/events-date/', requestOptions)
        .then(res => res.json())
        .then(data => data)
}

function getEvent(id) {
    const requestOptions = {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
            'X-CSRFToken': cookies.getCookie('csrftoken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }

    return fetch(`/api/events/?event=${id}`, requestOptions)
        .then(res => res.json())
        .then(data => data)
}

function getEventsCarousel(page) {
    const requestOptions = {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
            'X-CSRFToken': cookies.getCookie('csrftoken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }

    return fetch(`/api/events/?category=${page}`, requestOptions)
        .then(res => res.json())
        .then(data => data)
}

function getMoreEvents(page) {
    const requestOptions = {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
            'X-CSRFToken': cookies.getCookie('csrftoken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }

    return fetch(`/api/events-date/?page=${page}`, requestOptions)
        .then(res => res.json())
        .then(data => data)
}

function getFilteredEvents(date) {
    const start = date.startDate.format('YYYY-MM-DD')
    const end = date.endDate.format('YYYY-MM-DD')
    const requestOptions = {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
            'X-CSRFToken': cookies.getCookie('csrftoken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }

    return fetch(`/api/events-date/?start=${start}&end=${end}`, requestOptions)
        .then(res => res.json())
        .then(data => data)
}
