import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { eventsActions, pageActions } from '../../_actions'
import '../../css/App.scss'
import { EventCard, EventList, EventModal } from '../'
import Modal from 'react-modal'
import { DateRangePicker } from 'mwm-react-dates'
import 'mwm-react-dates/lib/css/_datepicker.css'
import ReactGA from 'react-ga'


class Calendar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            listView: false,
            cardResults: 12,
            listResults: 4,
            startDate: null,
            endDate: null,
            focusedInput: null,
            modalIsOpen: false,
        }
    }

    componentWillReceiveProps(nextProps) {
        const currentPage = this.props.location.pathname;
        const nextPage = nextProps.location.pathname;

        if (currentPage !== nextPage && process.env.NODE_ENV !== 'development') {
            ReactGA.pageview(window.location.pathname)
        }
    }

    componentDidMount() {
        const { events, dispatch, calendar, news, singleEvent, error } = this.props

        window.scrollTo(0, 0)

        if (!Object.keys(events).length) {
            dispatch(eventsActions.getEventsList())
        }

        if (!calendar) {
            dispatch(pageActions.getPage('calendar'))
        }

        if (!news) {
            dispatch(pageActions.getPage('news'))
        }

        const search = {}
        window.location.search.split(/\?|&/g).forEach(e => {
            const parts = e.split('=')
            search[parts[0]] = parts[1]
        })

        if (search.event && !singleEvent && !error) {
            dispatch(eventsActions.getEvent(search.event))
            this.setState({ modalIsOpen: true })
        }

        this.updateDimensions()
        window.addEventListener('resize', this.updateDimensions)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions)
    }

    updateDimensions = () => {
        if (window.innerWidth < 800) {
            if (!this.state.mobileList) {
                this.setState({ mobileList: true })
            }
        } else {
            if (this.state.mobileList) {
                this.setState({ mobileList: false })
            }
        }
    }

    changeView = () => {
        this.setState({
            listView: !this.state.listView,
            cardResults: 12,
            listResults: 4,
        })
    }

    getFilteredDates = (date) => {
        const { dispatch } = this.props
        if (date.startDate && date.endDate) {
            dispatch(eventsActions.getFilteredEvents(date))
        }
    }

    openCalendar = () => this.setState({ focusedInput: 'startDate' })

    loadMore = () => {
        const { dispatch, events, pageNumber, filteredEvents } = this.props
        if (this.state.listView) {
            const listResults = this.state.listResults + 4
            this.setState({ listResults })
            if (!Object.keys(filteredEvents).length && listResults > Object.keys(events).length) {
                dispatch(eventsActions.getMoreEvents(events, pageNumber))
            }
        } else {
            const cardResults = this.state.cardResults + 12
            this.setState({ cardResults })
            if (!Object.keys(filteredEvents).length && cardResults > Object.keys(events).length) {
                dispatch(eventsActions.getMoreEvents(events, pageNumber))
            }
        }
    }

    clearSearch = () => {
        const { dispatch } = this.props
        this.setState({ startDate: null, endDate: null })
        dispatch(eventsActions.clearFilteredEvents())
    }

    closeModal = () => this.setState({ modalIsOpen: false })

    render() {
        const { events, filteredEvents, calendar, news, singleEvent } = this.props
        const filteredLength = Object.keys(filteredEvents).length
        const eventList = filteredLength ? filteredEvents : events
        const page = calendar || {}
        const newsPage = news || {}

        let buttonClass
        if (filteredLength && (filteredLength <= this.state.cardResults || filteredLength <= this.state.listResults)) {
            buttonClass = 'load-more button disabled'
        } else {
            buttonClass = 'load-more button'
        }

        let date = ''
        if (singleEvent) date = singleEvent[0].start_date.split('-')

        return (
            <div className="content" id="calendar">
                <div className="head">
                    <div className="title">
                        { page.title || 'Calendar' } / <Link to="/news" className="alt-link">{ newsPage.title || 'News' }</Link>
                    </div>
                    <div className="options info">
                        {
                            this.state.listView ? (
                                <div onClick={ this.changeView } className="change-view">
                                    <div className="calendar-view-icon"></div>Calendar View
                                </div>
                            ) : (
                                <div onClick={ this.changeView } className="change-view">
                                    <div className="list-view-icon"></div>List View
                                </div>
                            )
                        }
                        <div className="filter-dates">
                            <div className="change-view" onClick={ this.openCalendar }><div className="filter-date-icon"></div>Filter Dates</div>
                            <span className={ this.state.endDate ? "clear-search" : "clear-search hide" } onClick={ this.clearSearch }>Clear</span>
                        </div>
                        <DateRangePicker
                            startDate={ this.state.startDate }
                            startDateId="your_unique_start_date_id"
                            endDate={ this.state.endDate }
                            endDateId="your_unique_end_date_id"
                            onDatesChange={ ({ startDate, endDate }) => this.setState({ startDate, endDate }) }
                            focusedInput={ this.state.focusedInput }
                            onFocusChange={ focusedInput => this.setState({ focusedInput }) }
                            numberOfMonths={ 1 }
                            enableOutsideDays={ true }
                            onClose={ this.getFilteredDates }
                            />
                    </div>
                </div>
                {
                    this.state.listView ? (
                        <div className="event-list">
                            {
                                !this.state.mobileList ? (
                                    <div className="header info">
                                        <div>Event</div>
                                        <div>Date + Time</div>
                                        <div>Location</div>
                                        <div>Details</div>
                                    </div>
                                ) : ''
                            }
                            {
                                Object.keys(eventList)
                                    .slice(0, this.state.listResults)
                                    .map((e, i) => eventList[e].length ? <EventList key={ i } date={ e } eventList={ eventList[e] } first={ i === 0 } mobileList={ this.state.mobileList }/> : '')
                            }
                        </div>
                    ) : (
                        <div className="card-list">
                            {
                                Object.keys(eventList)
                                      .slice(0, this.state.cardResults)
                                      .map((e, i) => <EventCard key={ i } date={ e } eventList={ eventList[e] } />)
                            }
                        </div>
                    )
                }
                {
                    Object.keys(eventList).length ? (
                        <div className="calendar-buttons">
                            <button className={ buttonClass } onClick={ this.loadMore }>Load More</button>
                        </div>
                    ) : ''
                }
                <Modal isOpen={ this.state.modalIsOpen }  shouldCloseOnOverlayClick={ true } onRequestClose={ this.closeModal }>
                    <EventModal events={ singleEvent || [] } modalIsOpen={ this.state.modalIsOpen } date={ date || ' ' } close={ this.closeModal }/>
                </Modal>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { events, singleEvent, pageNumber, filteredEvents } = state.events
    const { calendar, news } = state.page
    return {
        events,
        singleEvent,
        pageNumber,
        filteredEvents,
        calendar,
        news,
    }
}
const connectedCalendar = connect(mapStateToProps)(Calendar)
export { connectedCalendar as Calendar }
