import React, { Component } from 'react'
import { LocationMenu } from '../'

class Map extends Component {
    constructor(props) {
        super(props)
        this.state = {
            places: this.props.places,
            map: this.props.map,
            categories: this.props.categories,
            activeMap: '',
            filter: {
                name: 'All',
                slug: '',
            },
            showSelect: false,
            smallMap: false,
            filteredLocation: null,
            mapLevel: '',
            skewedMapClass: 'skewed-maps',
            flatMapClass: 'flat-maps hide-map collapsed',
            mapLevel3Class: 'places-level-3-map map-image hide-map collapsed',
            mapLevel2Class: 'places-level-2-map map-image hide-map collapsed',
            mapLevel1Class: 'places-level-1-map map-image hide-map collapsed',
        }
    }

    componentWillReceiveProps(nextProps) {
        // this updates the state when it is changed in the parent element
        this.setState({
            places: nextProps.places,
            map: nextProps.map,
            categories: nextProps.categories,
         })
    }

    componentDidMount() {
        this.updateSize()
        window.addEventListener('resize', this.updateSize)

        const search = {}
        window.location.search.split(/\?|&/g).forEach(e => {
            const parts = e.split('=')
            search[parts[0]] = parts[1]
        })

        if (search.location) {
            const filteredLocation = decodeURIComponent(search.location)
            this.setState({ filteredLocation, filter: { name: filteredLocation } })
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateSize)
    }

    updateSize = () => {
        if (window.innerWidth <= 1000 && !this.state.smallMap) {
            this.setState({ smallMap: true })
        } else if (window.innerWidth > 1000 && this.state.smallMap) {
            this.setState({ smallMap: false })
        }
    }

    appendMapIcons = (level, mapHeight, places, full, parking) => {
        const icons = places.filter((e) => e.level.match(level) || e.level === 'All Levels');
        let url
        if (level === 'Level 1') {
            url = full ? 'map_icon_level1' : 'skewed_map_icon_level1'
        } else if (level === 'Level 2') {
            url = full ? 'map_icon_level2' : 'skewed_map_icon_level2'
        } else if (level === 'Level 3') {
            url = full ? 'map_icon_level3' : 'skewed_map_icon_level3'
        }
        if (parking) icons.push({ [url]: parking })
        if (icons[0]) return createIcons(icons[0])

        function createIcons(place, i=0) {
            i++
            if (!icons[i]) {
                return (
                    <div className="map-icon"
                        style={{
                            backgroundImage: `url(${place[url]})`,
                            paddingBottom: `${mapHeight}%`
                        }}
                    />
                )
            }
            return(
                <div className="map-icon"
                    style={{
                        backgroundImage: `url(${place[url]})`,
                        paddingBottom: `${mapHeight}%`
                    }}
                >
                    { createIcons(icons[i], i) }
                </div>
            )
        }
    }

    changeFilter= (e) => {
        const name = e.target.getAttribute('name')
        const slug = e.target.getAttribute('slug')
        const filter = { name, slug }
        const showSelect = false
        const filteredLocation = null
        this.setState({ filter, showSelect, filteredLocation })
    }

    toggleSelectMenu = () => {
        const showSelect = !this.state.showSelect
        this.setState({ showSelect })
    }

    categories = (cats) => {
        if (cats) {
            return cats.join(', ')
        }
    }

    showLevel = (mapLevel) => {
        const level = this.state.mapLevel || mapLevel
        this.setState({ filter: { name: 'All', slug: '' }, showSelect: false, mapLevel })
        if (!mapLevel) {
            this.setState({ [`mapLevel${level}Class`]: `places-level-${level}-map map-image hide-map` })
            setTimeout(() => {
                this.setState({
                    [`mapLevel${level}Class`]: `places-level-${level}-map map-image hide-map collapsed`,
                    skewedMapClass: 'skewed-maps hide-map',
                })
                setTimeout(() => {
                    this.setState({ skewedMapClass: 'skewed-maps'})
                    window.scrollTo(0, 0)
                }, 10);
            }, 300);
        } else {
            this.setState({ skewedMapClass: 'skewed-maps hide-map'})
            setTimeout(() => {
                this.setState({
                    skewedMapClass: 'skewed-maps hide-map collapsed',
                    [`mapLevel${level}Class`]: `places-level-${level}-map map-image hide-map`
                })
                setTimeout(() => {
                    this.setState({ [`mapLevel${level}Class`]: `places-level-${level}-map map-image` })
                    window.scrollTo(0, 0)
                }, 10);
            }, 300);
        }
    }

    render() {
        const locationClasses = {
            'Level 1': 'level-1',
            'Level 2': 'level-2',
            'Level 3': 'level-3',
            'Level 1 and Level 2': 'level-1-2',
            'Level 2 and Level 3': 'level-2-3',
            'All Levels': 'all-levels',
        }

        const mapHeight = (580 / 2111) * 100 // Skewed map height / width * 100 for percentage
        const keyHeight = (466 / 2110) * 100 // key height / width * 100 for percentage
        const flatMapHeight = (1388 / 2111) * 100
        let places = this.state.places || []

        if (this.state.filteredLocation) {
            places = places.filter(e => e.name.match(this.state.filteredLocation) || e.level === 'All Levels')
        } else if (this.state.filter.slug){
            places = places.filter(e => e.place_types.indexOf(this.state.filter.slug) !== -1 || e.level === 'All Levels')
        }

        if (this.state.mapLevel) {
            places = places.filter(e => e.level.indexOf(this.state.mapLevel) !== -1 || e.level === 'All Levels')
        }
        const filter = this.state.filter
        filter.placeString = JSON.stringify(places)

        return (
            <div className="map">
                <div className={ this.state.mapLevel ? 'close-map' : 'hide' } onClick={ () => this.showLevel() }>&#xd7;</div>
                <LocationMenu filter={ filter }
                              showSelect={ this.state.showSelect }
                              categories={ this.state.categories.filter(e => JSON.stringify(places).match(e.slug)) }
                              changeFilter={ this.changeFilter }
                              toggleSelectMenu={ this.toggleSelectMenu }
                              hide={ !this.state.smallMap }
                />
                <div className="location-maps">
                    <div className={ this.state.skewedMapClass }>
                        <div className="places-level-3-map map-image"
                             onClick={ () => this.showLevel(3) }
                             style={{
                                 backgroundImage: `url(${this.state.map.level3_skewed})`,
                                 paddingBottom: `${mapHeight}%`
                             }}
                        >
                            { this.state.places.length ? this.appendMapIcons('Level 3', mapHeight, places) : '' }
                        </div>
                        <div className="places-level-2-map map-image"
                             onClick={ () => this.showLevel(2) }
                             style={{
                                backgroundImage: `url(${this.state.map.level2_skewed})`,
                                paddingBottom: `${mapHeight}%`
                             }}
                        >
                            { this.state.places.length ? this.appendMapIcons('Level 2', mapHeight, places) : '' }
                        </div>
                        <div className={ this.state.mapLevel ? 'places-level-1-map map-image' : 'places-level-1-map map-image' }
                             onClick={ () => this.showLevel(1) }
                             style={{
                                backgroundImage: `url(${this.state.map.level1_skewed})`,
                                paddingBottom: `${mapHeight}%`
                             }}
                        >
                            { this.state.places.length ? this.appendMapIcons('Level 1', mapHeight, places) : '' }
                        </div>
                    </div>

                    <div className="flat-maps">
                        <div className={ this.state.mapLevel3Class }
                             onClick={ () => this.showLevel() }
                             style={{
                                 backgroundImage: `url(${this.state.map.level3})`,
                                 paddingBottom: `${flatMapHeight}%`
                             }}
                        >
                            { this.state.places.length ? this.appendMapIcons('Level 3', flatMapHeight, places, true, this.state.map.parking_level3) : '' }
                        </div>
                        <div className={ this.state.mapLevel2Class }
                             onClick={ () => this.showLevel() }
                             style={{
                                backgroundImage: `url(${this.state.map.level2})`,
                                paddingBottom: `${flatMapHeight}%`
                             }}
                        >
                            { this.state.places.length ? this.appendMapIcons('Level 2', flatMapHeight, places, true, this.state.map.parking_level2) : '' }
                        </div>
                        <div className={ this.state.mapLevel1Class }
                             onClick={ () => this.showLevel() }
                             style={{
                                backgroundImage: `url(${this.state.map.level1})`,
                                paddingBottom: `${flatMapHeight}%`
                             }}
                        >
                            { this.state.places.length ? this.appendMapIcons('Level 1', flatMapHeight, places, true, this.state.map.parking_level1) : '' }
                        </div>
                    </div>

                    <div className="map-key map-image"
                         style={{
                            backgroundImage: `url(${this.state.map.key})`,
                            paddingBottom: `${keyHeight}%`
                         }}
                    />
                </div>
                <div className="location-list white">
                    <LocationMenu filter={ this.state.filter }
                                  showSelect={ this.state.showSelect }
                                  categories={ this.state.categories }
                                  changeFilter={ this.changeFilter }
                                  toggleSelectMenu={ this.toggleSelectMenu }
                                  hide={ this.state.smallMap }
                    />
                    <div className={ this.state.showSelect ? 'hide' : 'locations' }>
                        {
                            places
                                .sort((a, b) => a.display_number > b.display_number ? 1 : -1)
                                .map((e, i) => (
                                    <div key={ i } className="location">
                                        <div className={ locationClasses[e.level] }>{ e.display_number }</div>
                                        <div className="location-list-info">
                                            <h3 className="location-name">{ e.name }</h3>
                                            <p className="location-category">{ this.categories(e.subcategories) }</p>
                                        </div>
                                    </div>
                                ))
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export { Map }
