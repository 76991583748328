import React, { Component } from 'react'
import '../../css/App.scss'
import { time } from '../../_helpers'
import { EventModal } from '../'
import Modal from 'react-modal'

class EventListItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            event: this.props.event,
            modalIsOpen: false,
            mobileList: this.props.mobileList,
            date: this.props.date,
        }
    }

    componentWillReceiveProps(nextProps) {
        // this updates the state when it is changed in the parent element
        this.setState({
            event: nextProps.event,
            mobileList: nextProps.mobileList,
            date: nextProps.date,
        })
    }

    openModal = () => this.setState({modalIsOpen: true})

    closeModal = () => this.setState({modalIsOpen: false})

    render() {
        const dateParts = this.state.date.split('-')
        const date = `${time.getMonth(dateParts[0])} ${Number(dateParts[1])}, ${dateParts[2]}`

        return (
            <div className="item">
                <div className="title">{ this.state.event.name }</div>
                <div className="date-time">
                    { !this.state.mobileList ? <div className="date info">{ date }</div> : '' }
                    {
                    this.state.event.repeat_text ? (
                            <div className="hours">
                                { this.state.event.repeat_text }
                                { !this.state.mobileList ? <br /> : ' ' }
                                { time.formatTime(this.state.event.start_time, this.state.event.end_time, this.state.event.time_info) }
                            </div>
                        ) : (
                            <div className="hours">
                                { time.formatTime(this.state.event.start_time, this.state.event.end_time, this.state.event.time_info) }
                            </div>
                        )
                    }
                </div>
                <div className="location">{ this.state.event.location }</div>
                <div className="more-info">
                    See more details <div className="right-arrow" onClick={ this.openModal } />
                </div>
                <Modal isOpen={ this.state.modalIsOpen } shouldCloseOnOverlayClick={ true } onRequestClose={ this.closeModal }>
                    <EventModal events={ [this.state.event] } modalIsOpen={ this.state.modalIsOpen } date={ dateParts } close={ this.closeModal }/>
                </Modal>
            </div>
        )
    }
}

export { EventListItem }
