function validate(value, rules) {
    let isValid = true
    for (let rule in rules) {
        switch (rule) {
            case 'minlength':
                isValid = isValid && minLengthValidator(value, rules[rule])
                break
            case 'maxlength':
                isValid = isValid && maxLengthValidator(value, rules[rule])
                break
            case 'isEmail':
                isValid = isValid && emailValidator(value)
                break
            case 'number':
                isValid = isValid && numberValidator(value)
                break
            default:
                isValid = true
        }

        if (isValid === false) break
    }

    return isValid

    function minLengthValidator(value, length) {
        return value.length >= length
    }

    function maxLengthValidator(value, length) {
        return value.length <= length
    }

    function emailValidator(value) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ //eslint-disable-line
        return re.test(String(value).toLowerCase())
    }

    function numberValidator(value) {
        return !isNaN(Number(value))
    }
}

const states = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'District Of Columbia',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
]

export const forms = {
    validate,
    states
}
