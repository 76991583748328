import { eventsConstants } from '../_constants'
const initialState = { events: {}, eventCarousel: {}, pageNumber: 1, filteredEvents: {} }

export function events(state = initialState, action) {
    switch (action.type) {
        case eventsConstants.GET_EVENTS_SUCCESS:
            return { ...state, events: action.events, pageNumber: 1 }
        case eventsConstants.GET_EVENT_SUCCESS:
            return { ...state, singleEvent: action.singleEvent }
        case eventsConstants.GET_MORE_EVENTS_SUCCESS:
            return { ...state, events: action.events, pageNumber: action.pageNumber }
        case eventsConstants.GET_FILTERED_EVENTS_SUCCESS:
            return { ...state, filteredEvents: action.events }
        case eventsConstants.GET_EVENTS_CAROUSEL_SUCCESS:
            return { ...state, eventCarousel: { ...state.eventCarousel, [action.page]: action.events } }


        case eventsConstants.GET_EVENTS_ERROR:
            return { ...state, error: action.error }
        case eventsConstants.GET_EVENT_ERROR:
            return { ...state, error: action.error }
        case eventsConstants.GET_MORE_EVENTS_ERROR:
            return { ...state, error: action.error }
        case eventsConstants.GET_FILTERED_EVENTS_ERROR:
            return { ...state, error: action.error }
        case eventsConstants.GET_EVENTS_CAROUSEL_ERROR:
            return { ...state, error: action.error }


        case eventsConstants.CLEAR_FILTERED_EVENTS:
            return { ...state, filteredEvents: {} }


        default:
            return state
    }
}
